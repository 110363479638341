/* Module: S */
// 1. HEADER
//.header {
//
//}

// 2. NAVIGATION LEFT
.navigation {

    &.slideout-menu {
        width: 320px;
    }
}

// 3. MEGAMENU
.megamenu {
    display: block;

    .megamenu__item {

        &:last-of-type {

            .megamenu__link {
                margin: 0;
            }
        }
    }

    .megamenu__link {
        font-size: pxToRem(14);
        margin:    0 pxToRem(40) 0 0;
        padding:   0;

        &::after {
            height: pxToRem(5);
            right:  pxToRem(-16);
            top:    pxToRem(8);
            width:  pxToRem(10);
        }

        &::before {
            @include sq-transform(translateY(pxToRem(16)));
        }

        &:hover,
        &:focus {

            &::before {
                @include sq-transform(translateY(pxToRem(8)));
            }
        }
    }

    .megamenu__link--open {

        &::before {
            @include sq-transform(translateY(pxToRem(8)));
        }
    }

    .megamenu__sub-nav {
        top: pxToRem(50);
    }
}
// 4. UTILITY LINKS ON NAVIGATIN LEFT
.utility__list {
    display: none;
}
