/* Module: S */
.header.design-2018 {
    .container-fluid {
        min-width: pxToRem(768);
    }

    .delwp__menu-wrap {
        display: none;
        &.header-active-class {
            display: block;
        }
        .delwp__menu {
            & > li {
                & > a {
                    width: calc(100% - 57px);
                }
            }
        }
    }

    .header-wrap {
        height:   pxToRem(90);
        padding:  0 pxToRem(255) 0 pxToRem(90);
        position: relative;
        top:      pxToRem(0);

        .header__logo-wrap {
            border-right:   none;
            padding:        pxToRem(0);
            position:       absolute;
            top:            0;
            right:          0;
            left:           auto;
            width:          pxToRem(212);
            height:         inherit;
            display:        flex;
            flex-direction: row;
            flex-grow:      0;
            flex-shrink:    1;
            flex-wrap:      wrap;

            &:before,
            &:after {
                height:            100%;
                content:           '';
                position:          absolute;
                top:               pxToRem(0);
                right:             pxToRem(25);
                -webkit-transform: skew(26.5deg);
                   -moz-transform: skew(26.5deg);
                     -o-transform: skew(26.5deg);
                        transform: skew(26.5deg);
            }

            &:before {
                width:             calc(100% - 5px);
                background-color:  $corporate-services;
            }

            &:after {
                width:             100%;
                background-color:  $corporate-services;
                z-index:           -1;
            }


            .header__logo,
            .delwp__menu-btn {
                display: inline-block;
            }

            .header__logo {
                background:      url('{{file_dest}}/logo-2018-tablet.svg') no-repeat left top;
                background-size: contain;
                width:           pxToRem(142);
                height:          pxToRem(53);
                position:        relative;
                top:             pxToRem(18.5);
                padding-right:   pxToRem(50);
                border-right:    pxToRem(1) solid $gray91;
            }

            .delwp__menu-btn {
                font-size:        0;
                position:         relative;
                top:              pxToRem(0);
                width:            pxToRem(70);
                height:           100%;
                background-color: $valhalla-dark;

                &:after {
                    display:         block;
                    content:         '';
                    position:        absolute;
                    top:             52%;
                    left:            50%;
                    width:           pxToRem(18);
                    height:          pxToRem(11);
                    background:      url('{{file_dest}}/chevron.svg') no-repeat center center;
                    background-size: cover;
                    transform:       translate(-50%, -52%) rotate(180deg) scale(1.2);
                }

                &.header-active-class {
                    &:after {
                        transform: translate(-50%, -51%) rotate(0deg) scale(1.2);
                    }
                }

                &:hover,
                &:focus {
                    outline: none;
                }
            }
        }

        .search-box__toggle {
            right: pxToRem(265);
            &.search-box__close {
                background-color:  $white;
            }
        }

        .header__title-search-wrap {
            display: inline-block;
            padding: pxToRem(26) pxToRem(9) pxToRem(18) pxToRem(31);
            position: static;

            .header__site {
                vertical-align: top;

                & > a {
                    line-height:    pxToRem(30);
                    font-size:      pxToRem(22);
                }
            }

            .header__search {

                &.header__search--active {
                    // left:             pxToRem(-90);
                    top:              pxToRem(90);
                    bottom:           pxToRem(-57);

                    input {
                        width:        calc(100% - 150px);
                    }
                }
            }
        }

        .megamenu__burger-wrap {
            .megamenu__burger {
                width:   pxToRem(90);
                height:  pxToRem(90);
                padding: pxToRem(39) pxToRem(31) pxToRem(34) pxToRem(33);
                left:    0;
                right:   auto;
            }

            .megemenu__back {
                padding-top:    pxToRem(32);
                padding-bottom: pxToRem(32);
                left:           0;
                right:          auto;
            }

            &.header-active-class{
                .megamenu__burger {
                    left:    pxToRem(351);
                    padding: pxToRem(35) pxToRem(35);
                }

                .megemenu__back {
                    width:      pxToRem(351);
                    & > span {
                        height: pxToRem(26);
                        padding:    0;
                    }
                }
            }
        }
    }

    .megamenu-wrap {
        width: 100%;

        nav {
            display: inline-block;
        }

        .megamenu__overlay {
            width:            calc(100% - 441px);
            height:           100%;
            position:         fixed;
            z-index:          -1;
            top:              0;
            bottom:           0;
            left:             pxToRem(441);
            background-color: rgba(0,0,0, 0.5);
            opacity:          0;
            display:          none;
        }

        &.header-active-class {
            .megamenu__overlay {
                z-index:  20;
                opacity:  1;
                display:  inline-block;
            }
        }

        .megamenu__list,
        .megamenu__list-l2,
        .megamenu__list-l3 {
            top:   pxToRem(89);
            right: auto;
            left:  0;

            &.header-active-class {
                width: pxToRem(441);
            }
        }

        .megamenu__list-l2 {
            & > li {
                & > a {
                    & > p {
                        font-size: pxToRem(14);
                    }
                }
            }
        }
    }
}
