/* Module: S */
// 1. CONTENT WRAPPER
.content-wrapper {

    .content-wrapper__col-header {
        font-size:  pxToRem(24);
        margin-top: 0;
    }

    &--one-col {

        p,
        ol,
        ul {
            width: 80%;
        }

    }

}

.feature-box {
    padding-top: 0;
}
