/* Module: S */
// 1. FEATURE PANEL
.topic-tiles {
    padding: pxToRem(50) 0 pxToRem(31) 0;

    .container-fluid {
        max-width: pxToRem(1024);

        .row {
            padding: 0 pxToRem(29);
        }
    }

    .topic-tiles__heading {
        font-size:   pxToRem(26);
        margin:      0 0 pxToRem(45) 0;
        padding:     0 pxToRem(115);
        line-height: pxToRem(35);
        text-align:  center;
    }

    .topic-tile__block--wrap {
        margin:     0 pxToRem(6) pxToRem(16) pxToRem(6);
        max-width:  pxToRem(165);
    }

    .topic-tile__block {
        padding:      pxToRem(110) pxToRem(10) pxToRem(20) pxToRem(10);
        border-width: 1.5px;

        html.no-touch &:hover,
        html.no-touch &:focus {
            outline:    none;
            background: $planning;
            padding:    pxToRem(20) pxToRem(10);

            .topic-tile__image {
                display: none;
            }

            .topic-tile__heading-wrap {
                .topic-tile__heading {
                    text-align:     left;
                    margin-bottom:  pxToRem(0);
                    font-size:      pxToRem(14);
                    font-weight:    500;
                    letter-spacing: -0.4px;
                }
            }

            .topic-tile__desp-wrap {
                display:        block;
                height:         pxToRem(77);
                color:          $white;
                font-size:      pxToRem(13);
                font-weight:    400;
                letter-spacing: -0.3px;
                text-align:     left;
                line-height:    pxToRem(15);
            }
        }

        .topic-tile__image {
            width:      pxToRem(50);
            height:     50%;
            top:        28%;
            left:       50%;
            transform:  translate(-50%, -36%);
        }

        .topic-tile__image--wide {
            width: pxToRem(72);
        }

        .topic-tile__heading-wrap {
            height:             pxToRem(32);
            .topic-tile__heading {
                font-size:      pxToRem(14);
                line-height:    pxToRem(16);
                letter-spacing: -0.3px;
                text-align:     center;
                position:       static;
                transform:      translateY(0);
            }
        }
    }
}

.topic-tile--popup {
    .l1--wrap {

        .popup--close-btn {
            &::before {
                width:           pxToRem(50);
                height:          pxToRem(50);
            }
        }
    }
}
