/* Module: S */
.property-dashboard {
    .lga-popup-container {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
    }
}

.property-dashboard.amendments {
    .search-header__title {
        max-width: 75%;
    }
}

.property-dashboard__drawer {
    .content-drawer &-link {
        float: right;
    }
}