/* Module: S */
.planning-scheme__map {
    border: none;
    height: pxToRem(400);
    width:  100%;
}

.map__markers {
    background-color: transparent;
    color: $corporate-services;
    cursor: pointer;
    display: block;
    font-family: $sans-serif;
    font-size: pxToRem(16);
    font-weight: 600;
    height: 50px;
    line-height: 1.75;
    padding: pxToRem(5) pxToRem(25);
    position: relative;
    text-align: center;
    text-transform: capitalize;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;

    &::before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $white;
        bottom: 0;
        content: '';
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

    &::after {
        background-color: $white;
        content: '';
        display: block;
        height: 40px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}

.maps {
    border: none;
    height: 38.4375rem;
    width: 100%;
  }