/* Module: S */
.inside {

    .main {
        overflow: hidden;
        position: relative;
        z-index: 1;

        &::before {
            border-color: $teal transparent transparent;
            border-style: solid;
            border-width: pxToRem(420) pxToRem(195) 0;
            content:      '';
            display:      none;
            right:        pxToRem(-195);
            position:     absolute;
            top:          0;
            width:        0;
            z-index:      9;
        }

        &::after {
            background: url('{{file_dest}}/body-triangles.svg') no-repeat center center transparent;
            content:    '';
            display:    none;
            height:     pxToRem(277);
            position:   absolute;
            right:      0;
            top:        pxToRem(800);
            width:      pxToRem(168);
            z-index:    9;
        }

        > .container-fluid {
            max-width:      pxToRem(1232);
            padding-bottom: pxToRem(30);
            padding-top:    0;
        }

        .lhs-nav-wrapper {
            display: none;
        }

        .main-content-wrapper {
            position: relative;
            z-index:  11;
        }

        &.col-2 {

            &::before {
                left:  pxToRem(-195);
                right: 0;
            }

            &::after {
                @include sq-transform(scaleX(-1));
                left:  0;
                right: auto;
            }
        }
    }
    .main.minimal,
    .main.reduced {
        &::before,
        &::after {
            content: none;
        }
    }
}

// Theme: Forest Fire Management
.forest-fire-management.inside {

    .main {

        &::before {
            border: 0 none;
        }

        &::after {
            background-image:    url('{{file_dest}}/bg-main-checker.svg');
            background-position: right top;
            height:              1142px;
            top:                 0;
            width:               468px;
        }

    }

}

// Theme: Fishermans Bend
.fishermans-bend.inside {
    .main {
        &::before {
            border-color: $fishermans-bend transparent transparent;
        }
        &::after {
            background: url('{{file_dest}}/body-triangles--fishermans.svg') no-repeat center center transparent;

        }
    }
}

// Detailed Project Page
.project {
    .main {
        margin-top: pxToRem(-30);
    }
}

.project-sections {
    width: 100%;
}

.project-section__sort {
    background: $white;
    border:     solid 1px $grey-c;
    height:     pxToRem(55);
    width:      100%;
    &.expanded {
        position: relative;

        .sort-project-section__list {
            display:          block;
            position:         absolute;
            z-index:          12;
            top:              pxToRem(-1);
            background-color: $white;
            box-shadow:       0 0 3px 0 rgba(32, 21, 71, 0.5);
            width:            100%;
            margin:           0;
            padding:          pxToRem(15) pxToRem(14);

            &:after {
                display:         block;
                content:         '';
                position:        absolute;
                top:             pxToRem(19);
                right:           pxToRem(15);
                width:           pxToRem(18);
                height:          pxToRem(11);
                background:      url('{{file_dest}}/chevron-corporate.svg') no-repeat center center;
            }

            li {
                list-style: none;
            }
            .project-section--selected {
                &.project-section__link {
                    color:  $corporate-services;
                }
            }
            .project-section__link {
                display:         block;
                font-size:       pxToRem(16);
                color:           $silver1;
                padding:         pxToRem(5) 0;
                text-decoration: none;
                &:hover,
                &:active {
                    cursor: pointer;
                    color:  $planning;
                }
            }
        }
    }
    .sort-project-section--selected {
        cursor:      pointer;
        display:     block;
        font-size:   pxToRem(15);
        font-weight: bold;
        padding:     pxToRem(18) pxToRem(15) pxToRem(17) pxToRem(15);

        &:after {
            display:         block;
            content:         '';
            float:           right;
            width:           pxToRem(18);
            height:          pxToRem(11);
            background:      url('{{file_dest}}/chevron-grey.svg') no-repeat center center;
        }
    }
    .sort-project-section__list {
        display: none;
    }
}

.project-section-content {
    max-width: pxToRem(950);
}

.project-section__target {
    display: none;
    margin-top: pxToRem(30);
    outline: 0;

    &.active {
        display: block;
    }
}

.project-browse-button {
    display:    block;
    text-align: center;

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    span {
        display:        block;
        font-size:      pxToRem(16);
        font-weight:    500;
        text-transform: uppercase;
    }
    background:  $teal;
    border:      none;
    color:       $corporate-services;
    font-size:   pxToRem(25);
    font-weight: bold;
    padding:     pxToRem(20) 0 pxToRem(30);
    width:       100%;
}



.faq__container--content.section {
    border-top: 0.03125rem solid rgba(32, 21, 71, 0.21);
    border-bottom: none;
    padding-top: 40px;

    .faq__content--status {
        font-size: pxToRem(18);
        font-weight: 600;
        color: #642667;
        margin-bottom: 0;
    }

    .faq__content--name {
        font-size: pxToRem(20);
        line-height: 1.4;
        font-weight: 400;
        margin: 0;
    }

    .submissions-date {
        margin-bottom: pxToRem(40);
        &__header {
            font-weight: 600;
            margin: 0;
        }
        &__date {
            margin-top: 0;
        }
    }
    .submission-documents {
        margin-bottom: pxToRem(20);
        &__header {
            font-weight: 600;
            margin: 0;
        }
        &__header,
        a {
            font-size: pxToRem(14);
        }
    }
}

.project {
    .pdf-link {
        color: #642667;
        display: block;
        margin: pxToRem(5) 0 pxToRem(5) 1.5rem;
        position: relative;
        &:before {
            content: "";
            background: url(mysource_files/file-pdf.svg) no-repeat center center transparent;
            width: 1rem;
            height: 1rem;
            display: block;
            position: absolute;
            background-size: contain;
            left: -1.5rem;
        }
    }
    .doc-link {
        color: #642667;
        display: block;
        margin: pxToRem(5) 0 pxToRem(5) 1.5rem;
        position: relative;
        &:before {
            content: "";
            background: url(mysource_files/word-icon.svg) no-repeat center center transparent;
            width: 1rem;
            height: 1rem;
            display: block;
            position: absolute;
            background-size: contain;
            left: -1.5rem;
        }
    }
    .file-link {
        color: #642667;
        display: block;
        margin: pxToRem(5) 0 pxToRem(5) 1.5rem;
        position: relative;
        &:before {
            content: "";
            background: url(mysource_files/file-icon.svg) no-repeat center center transparent;
            width: 1rem;
            height: 1rem;
            display: block;
            position: absolute;
            background-size: contain;
            left: -1.5rem;
        }
    }

}

