/* Module: S */
.main .property-dashboard {
    // padding-bottom: 60px;
    border-bottom: pxToRem(1) solid $teal;
}

.property-dashboard.amendments {
    .search-header__title {
        font-size: pxToRem(28);
        margin-bottom: 0;
        text-transform: uppercase;

        &--empty {
            background-color: $purple-light;
            display: block;
            width: 75%;
            height: pxToRem(30);
            border-radius: pxToRem(10);
        }
    }

    .search-header__sec-desp {
        margin: 0;

        &--empty {
            background-color: $purple-light;
            display: block;
            width: 25%;
            height: pxToRem(20);
            border-radius: pxToRem(8);
        }
    }

    .search-header__change-plnning-btn {
        margin-top: pxToRem(27);
    }
}

.top-bar {
    background: #f6f6f6;

    &__buttons-wrapper {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        padding: 10px 0 15px;
    }

    &__button {
        position: relative;
        width: 100%;
        padding: 40px 15px 16px;
        border: 1px solid #865587;
        font-size: 12px;
        color: #865587;
        font-weight: 600;
        text-decoration: none;
        text-align: center;

        &--pdf-property:before,
        &--pdf-download:before,
        &--map:before,
        &--map-pd:before,
        &--map-ms:before,
        &--go-to:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 15px;
            left: 50%;
            transform: translateX(-50%);
        }

        &--lga-name {
            text-transform: capitalize;
            display: none;
        }

        &--pdf-download:before,
        &--pdf-property:before {
            background: url(mysource_files/file-pdf.svg) no-repeat center center transparent;
            background-size: contain;
        }

        &--pdf-property {
            margin-right: 8px;
        }

        &--map,
        &--map-pd,
        &--map-ms {
            margin-left: 8px;

            &:before {
                background: url(mysource_files/map-pin--purple.svg) no-repeat center center transparent;
                background-size: contain;
            }
        }

        &--go-to {
            margin-left: 8px;

            &:before {
                background: url(mysource_files/list-arrow.svg) no-repeat center center transparent;
                background-size: contain;
            }
        }

        &--disabled {
            background: $cornflower-blue !important;
            color: $cornflower-blue !important;

            &::after,
            &::before {
                visibility: hidden;
            }
        }
    }
}

// index content
.property-dashboard {
    padding-bottom: pxToRem(50);

    .lga-popup-container {
        top: 100%;
        right: 8px;
        left: 8px;
    }

    &__content {
        width: 100%;
    }

    &__item--text {
        padding: 10px 0;

        &.underline {
            border-bottom: solid 1px #c8c8c8;
        }
    }

    &__item--map {
        margin: 0;
        width: 100%;
        height: 360px;

        #map {
            width: 100%;
            height: 100%;

            iframe {
                border: 0;
                background-color: $medium-grey;
            }
        }

        &-empty {
            min-height: pxToRem(150);
            background-color: $cornflower-blue;
        }
    }

    &__item-header {
        margin: 10px 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.11;
        text-transform: uppercase;
    }

    &__item-question {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: normal;
        color: #6f6f6f;
    }

    &__item-answer {
        margin-bottom: 10px;
        margin-top: 0;
        font-size: 16px;
        font-weight: 600;
    }

    &__item-answer--list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item-answer--empty {
        background: $cornflower-blue;
        border-radius: pxToRem(8);
        display: block;
        width: 100%;
        max-width: 75%;
        height: pxToRem(20);
        margin-bottom: pxToEm(10);
    }

    &__link {
        display: block;
        color: $corporate-services;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &--empty {
            color: $cornflower-blue;
            text-decoration: none;
            background-color: $cornflower-blue;
            border-radius: pxToRem(8);
        }
    }

    &__related {
        color: #642667;
        font-weight: bold;

        &_details {
            background: #642667;
            display: inline-block;
            position: relative;
            width: auto;
            padding: 15px 30px;
            border: none;
            font-size: 14px;
            color: white;
            font-weight: 600;
            text-decoration: none;
            text-align: center;
        }
    }
}

.council-link {
    word-wrap: break-word;
    color: $black;
    text-decoration: none;
}

.x1tox3 {
    li {
        text-transform: uppercase;
    }
}

.property-dashboard__drawer {

    &-heading {
        color: $planning;
        font-weight: 800;
        padding: 0;
        margin: 0 0 pxToRem(8);
        text-transform: uppercase;
        line-height: pxToRem(28);
        font-size: pxToRem(20);
    }

    &-address {
        color: $corporate-services;
        padding: 0;
        margin: 0 0 pxToRem(30);
        font-weight: 800;
        text-transform: capitalize;
        line-height: pxToRem(49);
        font-size: pxToRem(35);
    }

    &-related {
        margin-bottom: pxToRem(50);

        &+.property-dashboard__drawer {
            margin-bottom: 0;
        }
    }

    &-related-heading {
        color: $mine-shaft;
        font-weight: 800;
        padding: 0;
        margin: 0 0 pxToRem(8);
        line-height: pxToRem(26);
        font-size: pxToRem(18);

    }

    &-related-list {
        border-bottom: pxToRem(2) solid $silver;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    &-related-list-item {
        border-top: pxToRem(2) solid $silver;
        overflow: hidden;
        color: $mine-shaft;
        font-size: pxToRem(18);
        padding: pxToRem(8);

        &:hover {
            background-color: $gallery;
        }
    }

    .content-drawer &-link {
        display: block;
        color: $planning;
        font-size: pxToRem(16);
    }

}