/* Module: S */
// Maps and Spatial Data - Branding
.msd-brand {

    .top-bar__button--pdf-download,
    .top-bar__button--pdf-property {
        background: $msd-button-teal
    }

    .top-bar__button--pdf-download {
        margin: 0
    }

    .top-bar__button--pdf-property {
        margin: 0 0.625rem 0 0;
    }

    .top-bar__button--map,
    .top-bar__button--map-pd,
    .top-bar__button--map-ms,
    .top-bar__button--go-to {
        background: $msd-button-purple;
    }

    .top-bar__button--map,
    .top-bar__button--map-pd,
    .top-bar__button--map-ms {
        margin: 0 0 0 0.625rem;
    }

    .top-bar__button--go-to {
        margin-left: auto;
    }

    .top-bar__button {
        color: $white;
        flex-basis: auto;
        max-width: none;
    }

    .top-bar__button--pdf-download::before,
    .top-bar__button--pdf-property::before {
        background: url('mysource_files/file-pdf--white.svg') no-repeat center center transparent;
        background-size: contain;
    }

    .top-bar__button--map::before,
    .top-bar__button--map-pd::before,
    .top-bar__button--map-ms::before {
        background: url('mysource_files/map-pin.svg') no-repeat center center transparent;
        background-size: contain;
    }
}