/* Module: S */
.amendments-header {
    background-color: rgba($planning, 0.79);
    color: $white;
    position: relative;
}

.amendments-header .breadcrumb {
    line-height: pxToRem(15);
    margin: 0 0 pxToRem(15) 0;
    padding: pxToRem(15) pxToRem(10) 0 pxToRem(10);
    list-style: none;
}

.amendments-header .breadcrumb__item {
    display: inline-block;

    &::after {
        content: '/';
        font-size: pxToRem(12);
        font-weight: 500;
        letter-spacing: pxToRem(-0.1);
        padding: 0 pxToRem(4) 0  pxToRem(6);
    }

    &:last-of-type::after {
        content: none;
    }
}

.amendments-header .breadcrumb a {
    color: $white;
    font-size: pxToRem(12);
    font-weight: 500;
    letter-spacing: pxToRem(-0.1);
    position: relative;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.amendments-header > .container-fluid {
    max-width: 77rem;
}

.amendments-header .lga-selector--menu {
    margin-top: 0;
}

.amendments-header .lga-selector {
    background: transparent;
}

.amendments-header .search-header__title,
.amendments-header .text-box__title,
.amendments-header .text-box__link,
.amendments-header .right-box__planning-scheme-link {
    color: $white;
}

.amendments-header .search-header__title{
    margin: 0;
}

.amendments-header .lga-selector__content-wrapper {
    padding: pxToRem(16);
}

.amendments-header .right-box__planning-scheme-link {
    text-decoration: none;
}

.amendments-header .lga-selector .right-box__planning-scheme svg {
    fill: $white;
}

.amendments-header .right-box__search-wrapper input[type="text"] {
    border: none;

    &:focus {
        outline: none;
        border: none;
    }
}

.amendments-map {
    background-color: $corporate-services;
    padding: pxToRem(20) 0;
}

.amendments-map.active {
    svg,
    .amendments-map__text {
        opacity: 1;
    }
}

.amendments-wrapper {
    display: flex;
    justify-content: center;
    max-width: 77rem;
}

.amendments-map svg {
    fill: $white;
    transition: ease-in-out opacity 1s;
}

.amendments-map__text {
    align-items: center;
    color: $white;
    display: flex;
    font-weight: normal;
    margin: 0;
    padding-left: pxToRem(10);
    transition: ease-in-out opacity 1s;
}