/* Module: S */
// 1. FEATURE PANEL
.topic-tiles {
    background-color: $white;
    border-bottom:    1px solid $silver;
    color:      $corporate-services;
    overflow:   hidden;
    padding:    pxToRem(45) 0;
    position:   relative;

    .container-fluid {
        max-width: pxToRem(768);
        padding:   0;

        .row {
            margin: 0;
            padding: 0 pxToRem(10);
        }
    }

    .topic-tiles__heading {
        display:     block;
        font-size:   pxToRem(22);
        font-weight: 600;
        margin:      0 0 pxToRem(35) 0;
        text-align:  left;
        line-height: pxToRem(24);
    }

    .topic-tile__block--wrap {
        padding:       0;
        margin-bottom: pxToRem(6);
    }


    .topic-tile__block {
        @include sq-transition(background .35s ease);
        background-color:$white;
        color:           inherit;
        display:         block;
        height:          auto;
        padding:         pxToRem(16) pxToRem(10) pxToRem(14) pxToRem(75);
        text-align:      left;
        text-decoration: none;
        border:          1px solid $corporate-services;
        position:        relative;
        transition:      none;

        html.no-touch &:hover,
        html.no-touch &:focus {
            outline:    none;
            background: $planning;
            padding-left: pxToRem(20);

            .topic-tile__image {
                display: none;
            }

            .topic-tile__heading-wrap {
                .topic-tile__heading {
                    color:       $white;
                    font-size:   pxToRem(15);
                    font-weight: normal;
                }
            }
        }

        .topic-tile__image {
            position:   absolute;
            left:       19px;
            width:      pxToRem(40);
            height:     50%;
            top:        50%;
            transform:  translateY(-50%);

            svg {
                width:  100%;
                height: 100%;
                fill:   $planning;

                .color-fill {
                    fill:   $planning;
                }

                .color-stroke {
                    stroke: $planning;
                }

                .white-fill {
                    fill:   $white;
                }
            }
        }

        @mixin topic-tiles-alt-color($color) {
            background-color: $color;
            color: $white;

            h3 {
                color: $white;
            }

            .topic-tile__image svg {
                fill: $white;

                .color-fill {
                    fill:   $white;
                }

                .color-stroke {
                    stroke: $white;
                }
            }

            &:hover,
            &:focus {
                background: $planning;
            }
        }

        &.topic-tile--highlight {
            @include topic-tiles-alt-color($navy);
        }
    }

    .topic-tile__heading-wrap {
        height:          pxToRem(38);

        .topic-tile__heading {
            font-size:      pxToRem(15);
            font-weight:    bold;
            margin:         0;
            letter-spacing: -0.15px;
            line-height:    normal;
            position:       relative;
            top:            50%;
            transform:      translateY(-50%);
            transition:     font-size 0.5s;
        }
    }

    .topic-tile__desp-wrap {
        display: none;
    }
}

@media screen and (min-width: 1439px){
// @media screen and (min-width: 1424px){
    .topic-tiles .container-fluid .row {
        -webkit-box-pack: start;
        -ms-flex-pack:    start;
        justify-content:  flex-start;
        text-align:       start;
    }
}

.mfp-wrap {
    width:    100%;
    height:   100%;
    position: fixed !important;
    z-index:  31;
    top:      0 !important;
    bottom:   0;
    left:     0;
    display:  block;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
}

.topic-tile--popup {
    background-color: $white;
    width:            100%;

    .container-fluid {
        padding: 0;

        &.bg-color__planning {
            background-color: $planning;
        }

        &.topic-tile__highlight {
            background-color: $navy;
        }
    }

    .row {
        margin:  0;
        min-width: pxToRem(320);
    }

    .l1--wrap {
        padding:  pxToRem(20);
        position: relative;

        .popup--close-btn {
            font-size: 0;
            color:     $white;
            display:   block;
            position:  relative;
            left:       0;
            &::before {
                display:         block;
                content:         '';
                position:        absolute;
                width:           pxToRem(35);
                height:          pxToRem(35);
                background:      url({{file_dest}}/popup-close.svg) no-repeat center center;
                background-size: contain;
            }

            html.no-touch &:hover,
            html.no-touch &:focus {
                outline: none;
                &::before {
                    cursor:           pointer;
                    background-image: url({{file_dest}}/popup-close__hover.svg);
                }
            }
        }

        .l1--img-wrap {
            width:  pxToRem(60);
            margin: 0 auto;

            svg {
                width:  100%;
                height: 100%;
                fill:   $white;

                .color-fill {
                    fill:   $white;
                }

                .color-stroke {
                    stroke: $white;
                }

                .white-fill {
                    fill:   $white;
                }
            }
        }

        .l1--heading-wrap {
            .l1--heading {
                color:       $white;
                font-size:   pxToRem(22);
                font-weight: 600;
                text-align:  center;
            }
        }
    }

    .l2--tile-wrap {
        background-color: $gallery;
        padding:          pxToRem(20);
        border-bottom:    1px solid $corporate-services;
        text-align:       left;

        .l2--tile {
            text-decoration: none;

            &:hover,
            &:focus,
            &:active {
                color:  $corporate-services;
                cursor: pointer;

                .l2-content--heading,
                .l2-content--desp {
                    text-decoration: underline;
                }
            }

            .l2--img-wrap,
            .l2-content {
                float:left;
            }

            .l2--img-wrap {
                width:     20%;
                max-width: pxToRem(50);
            }

            .l2-content {
                width:   80%;
                .l2-content--heading-wrap {
                    margin-bottom:   pxToRem(10);

                    .l2-content--heading {
                        display:     inline;
                        font-size:   pxToRem(18);
                        font-weight: 600;
                        margin:      pxToRem(0);
                        color:       $corporate-services;
                    }
                }

                .l2-content--desp-wrap {
                    .l2-content--desp {
                        display:     inline;
                        font-size:   pxToRem(14);
                        font-weight: 400;
                        margin:      pxToRem(0);
                        color:       $corporate-services;
                    }
                }
            }
        }
    }

    .l3--wrap {
        background-color: $white;
    }

    .l3--wrap:not(.l3--wrap__heading-wrap) {
        .l3--tile-wrap {
            .l3--heading-wrap {
                display: block;
            }
        }
    }

    .l3--wrap {
        &.l3--wrap__heading-wrap {
            display: none;
        }
    }

    .l3--tile-wrap {
        padding:          pxToRem(20);

        .l3--heading-wrap {
            .l3--heading {
                font-size:      pxToRem(18);
                font-weight:    600;
                text-align:     left;
                text-transform: uppercase;
                margin-top:     0;
            }
        }

        .l3--links-list {
            text-align:   left;
            padding-left: pxToRem(0);
            .l3--link-item {
                list-style:    none;
                margin-bottom: pxToRem(10);

                .l3--link__icon-wrap {
                    display:      inline-block;
                    width:        pxToRem(25);
                    height:       pxToRem(16.6);
                }

                .l3--link {
                    display:             block;
                    font-size:           pxToRem(17);
                    font-weight:         500;
                    text-decoration:     underline;
                    color:               $corporate-services;
                    margin-left:         pxToRem(41);
                    margin-top:          pxToRem(-27);
                    letter-spacing:      -0.1px;

                    &:hover,
                    &:focus,
                    &:active {
                        font-weight: 600;
                        color:       $corporate-services;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .topic-tile--popup .container-fluid .row.l3--wrap {
        -webkit-box-pack: start;
        -ms-flex-pack:    start;
        justify-content:  flex-start;
        text-align:       start;
    }
}



.topic-tile__loader {
    display: none;
    color: white;
    font-size: 20px;
    margin: auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    transform: translateX(-50%);
    @at-root .topic-tile__block.loading & {
        display: block;
    }
}
.topic-tile__block.loading {
    .topic-tile__desp-wrap {
        visibility: hidden;
    }
    .topic-tile__heading-wrap {
        visibility: hidden;
    }
}

@-webkit-keyframes load4 {
    0%,
    100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}
@keyframes load4 {
    0%,
    100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}
