/* Module: S */
// 1. TITLE BANNER
.title-banner-2018 {
    .title-banner__topic-wrapper {
        display: block;

        .title-banner__topic {
            padding: pxToRem(75) pxToRem(120) pxToRem(23) 0;
            margin:      0;
            font-size:   pxToRem(35);
            font-weight: 500;
        }
    }

    .title-banner__navigation {
        display: block;
        .title-banner__list {
            display:        flex;
            flex-direction: row;
            flex-grow:      0;
            flex-shrink:    1;
            flex-wrap:      wrap;
        }
    }

    .title-banner__heading-wrapper {
        padding: pxToRem(18) 0 pxToRem(55);

        .title-banner__heading {
            font-size:   pxToRem(46);
            font-weight: 600;
            line-height: pxToRem(62);
        }

        .title-banner__descr {
            font-size:   pxToRem(19);
            font-weight: 500;
            line-height: pxToRem(26);
        }
    }
}
// Title Banner on Detailed project page
.project .title-banner{
    padding-bottom: pxToRem(50);
    .breadcrumb {
        margin-top: pxToRem(47);
    }
}