/* Module: S */
// Maps and Spatial Data - Branding
.msd-brand {

    .header.design-2018 .delwp__menu-wrap,
    .header.design-2018 .header-wrap .megamenu__burger-wrap .megamenu__burger,
    .header.design-2018 .header-wrap .header__logo-wrap .delwp__menu-btn,
    .header.design-2018 .header-wrap .header__logo-wrap::after,
    .header.design-2018 .header-wrap .header__logo-wrap::before,
    .header.design-2018 .header-wrap .megamenu__burger-wrap .megemenu__back,
    .header.design-2018 .header-wrap .megamenu__burger-wrap.header-active-class .megamenu__burger.megamenu__close:hover,
    .header.design-2018 .header-wrap .megamenu__burger-wrap.header-active-class .megamenu__burger.megamenu__close:focus,
    .title-banner-2018 .title-banner__topic-wrapper {
        background-color: $msd-menu;
    }

    .header.design-2018 .delwp__menu-wrap .delwp__home::before,
    .header.design-2018 .header-wrap .megamenu__burger-wrap .megamenu__burger.megamenu__burger-orig:hover,
    .header.design-2018 .header-wrap .megamenu__burger-wrap .megamenu__burger.megamenu__burger-orig:focus,
    .title-banner-2018 .title-banner__navigation {
        background-color: $msd-dark;
    }

    .search-header {
        background-color: $msd-dark
    }

    .main .property-dashboard {
        border-bottom: 0 none;
    }

    .top-bar__buttons-wrapper {
        flex-wrap: wrap;
    }

    .top-bar__button {
        border-color: $msd-button-purple;
        color: $msd-button-purple;
        flex-basis: calc(50% - 10px);
        margin: 5px;
        max-width: calc(50% - 10px);
    }

    .top-bar__button--pdf-download::before,
    .top-bar__button--pdf-property::before {
        background: url('mysource_files/file-pdf--msd.svg') no-repeat center center transparent;
        background-size: contain;
    }

    .top-bar__button--map::before,
    .top-bar__button--map-pd::before,
    .top-bar__button--map-ms::before {
        background: url('mysource_files/map-pin--msd.svg') no-repeat center center transparent;
        background-size: contain;
    }

    .property-dashboard__related {
        color: $msd-button-purple;
    }

    .property-dashboard__related_details {
        background: $msd-button-purple;
    }
}

@media (min-width: 25em) {
    .msd-brand {
        .top-bar__button {
            flex-basis: calc(25% - 10px);
            margin: 0 5px;
            max-width: calc(25% - 10px);
            word-break: break-word;
        }
    }
}