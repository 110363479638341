/* Module: S */
/*
 * Table of Contents
 *
 *  SEARCH TOOLS
 *  FILTERS
 *
 */



div#sidebar:empty {
    display: none; // for not found layout
}
/*
--------------------
1. SEARCH TOOLS
--------------------
*/

.results--count {
    letter-spacing: photoshopLetterspacingToEms(100);
    padding: 0 0;
    margin: 0 0;  
    color: $grey;
    font-size: pxToRem(13);
    line-height: pxToRem(15);
    text-transform: uppercase;
    font-weight: 500;
}

.results--query {
    padding: 0 0;
    margin: 0 0;  
    color: $dark-blue;
    font-size: pxToRem(20);
    line-height: pxToRem(22);
    font-weight: 300;
    margin-bottom: pxToRem(20);
    text-transform: capitalize;

    i {
        a {
            color: initial;
        }
    }
}

.results--types {

    @extend %inline-block;
    padding: 0 0;
    margin: 0 0;
    overflow: hidden;
    width: 100%;
    font-size: pxToEm(11);
    float: right;

    li {
        @extend %inline-block;
        float: none;
        clear: both;
        padding: 0 0;
        margin: 0 pxToRem(20) 0 0;
        font-size: pxToRem(15);
        line-height: pxToRem(18);
        width: auto;
        color: $medium-blue;

        a {
            &.current {
                color: $dark-blue;
                cursor: default;
            }

        }
    }
}

.types__select {
    font-size: pxToRem(11);
    font-weight: 700;
    padding: pxToRem(10)!important;
    line-height: pxToRem(13)!important;
    margin-bottom: 15px;
}

/*
--------------------
2. FILTERS
--------------------
*/

.filters__content {
    @extend %clearfix;
}


.more--facets {
    position: relative;
    float: left;
}


.filters__heading {
    position: relative;
    float: left;
    padding: 0 0;
    margin: pxToRem(20) 0 0 0;
    color: $dark-blue;
    font-size: pxToRem(17);
    line-height: pxToRem(20);
    font-weight: 500;

    &.first-child {
        margin: 0 0;
    }
}

.filters__heading-selected {
    @extend .filters__heading;
    font-size: pxToRem(15)!important;
    letter-spacing: photoshopLetterspacingToEms(100);

    &.first-child {
        margin-top: 0!important;
    }

}

.facet {
    position: relative;
    float: left;
    width: 100%;
}

.filters__group-h {
    @extend %clearfix;
    position: relative;
    float: left;
    width: 100%;
    padding-bottom: pxToRem(5);
    margin-bottom: pxToRem(5);
    border-bottom: 1px solid $grey-c;

    &.selected {
        padding-bottom: pxToRem(5);
        border-bottom: 0;

        .filters__more-button {
            top: 6px;
        }
    }
}

.filters__selected-group {
    @extend %clearfix;
    position: relative;

}

.facetLabel {
    margin: pxToRem(15) 0 0 0;
    padding: 0 0;
    float: left;
    position: relative;
    color: $dark-blue;
    font-size: pxToRem(15);
    line-height: pxToRem(18);
    font-weight: 500;
    text-transform: capitalize;

    &.selected {
        font-weight: 400;
        margin-top: pxToRem(7);
    }
}

.filters__more-button {
    position: absolute;
    right: 0;
    bottom: 8px;
    display: block;
    font-size: pxToRem(12);
    line-height: pxToRem(13);
    font-weight: 700;        
    letter-spacing: photoshopLetterspacingToEms(100);
}

/*
.search-toggle-more-categories {
    display: none;
}
*/

.filters__list {
    @extend %list-reset;
    position: relative;
    float: left;
    width: 100%;
}

.filters__item {
    position: relative;
}

.filters__link {
    margin: 0 0;
    padding: 0 0;
    font-size: pxToRem(13);
    line-height: pxToRem(24);
    text-transform: capitalize;
}

.filters__count {
    margin: 0 0;
    padding: 0 0;
    font-size: pxToRem(13);
    line-height: pxToRem(16);  
    color: $grey; 
}

.filter__panel {

    display: none;
    .filter__panel-close {
        display: none;
    }

    &.open {
        display: block!important;

        .filter__panel-close {
            display: block;
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: pxToRem(16);
        }

    }
}

.selected .filters__group-h, .hide-category, .facet-hidden, #sidebar .hide, .hide  {
    display: none;
}




.show-hidden .hide-category {
    display: list-item;
}

.show-hidden-facets .facet-hidden {
    display: block;
}
.filters__group-h.selected .facet-hidden {
    display: block;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: .75s;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}


@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
      height: 0;
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

.autosuggest-results.hide {
    display: none;
}

.autosuggest-results--header,
.autosuggest-results {
    border-bottom: 1px solid;
    border-left:   1px solid;
    border-right:  1px solid;
    left:          0;
    width:         100%;
}

.autosuggest-results {
    top: 69px;
}

.autosuggest-results--mobile {
    width: 230px;
}

.autosuggest-results--header,
.autosuggest-results--mobile,
.autosuggest-results {
    background: $white;
    position:   absolute;
    z-index:    100;

    ul {
        @include sq-list-reset();
        padding:    pxToRem(15) pxToRem(19);
        text-align: left;
    }

    li {
        padding: pxToRem(4) 0;
    }

    .autosuggest-item {
        color:           $corporate-services;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }   
}

.icon--tools {
    .search-tools-sitecontent & {
        display: none !important;
    }
    .search-tools-events & {
        display: none !important;
    }
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0; height: auto; min-height:100px; margin-bottom: 20px; }
    100% {opacity: 1;}
}

@keyframes fadeIn {
    0% {opacity: 0; }
    100% {opacity: 1;}
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}


@-webkit-keyframes fadeDown {
    0% {opacity: 0; height: 0; min-height: 0; }
    100% {opacity: 1; height: auto; min-height:100px; margin-bottom: 20px; }
}

@keyframes fadeDown {
    0% {opacity: 0; height: 0; min-height: 0; }
    100% {opacity: 1; height: auto; min-height:100px; margin-bottom: 20px; }
}

.fadeDown {
    -webkit-animation-name: fadeDown;
    animation-name: fadeDown;
     animation-timing-function: ease-out;
}



@-webkit-keyframes fadeUp {
    0% {opacity: 1;}
    100% {opacity: 0; height: 0; min-height: 0; margin-bottom: 0; }
}

@keyframes fadeUp {
    0% {opacity: 1;}
    100% {opacity: 0; height: 0; min-height: 0; margin-bottom: 0; }
}

.fadeUp {
    -webkit-animation-name: fadeUp;
    animation-name: fadeUp;
     animation-timing-function: ease-in;
}


.autosuggest-item {
    width: 100%;
    display: block;
}

.increase-size {
    font-size: 150%;
}

//
//.autosuggest-results {
//    background: $medium-grey;
//    ul {
//        margin: 0;
//        padding: 10px 0;
//        li {
//            list-style: none;
//            border-bottom: 1px #ccc dashed;
//            padding: 4px 2px;
//            margin: 0 2px;
//
//        }
//        a {
//            color: #201547;
//            text-decoration: none;
//        }
//    }
//}
