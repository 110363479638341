/* Module: S */
// 1. HEADER BANNER
.header-banner {
    max-height: pxToRem(580);

     .col-lg-6 {

        &:first-of-type {
            flex-basis: 45.8%;
            max-width: 45.8%;
        }

        &:last-of-type {
            flex-basis: 54.2%;
            max-width: 54.2%;
        }
    }

    .landing &,
    .inside & {
        max-height: pxToRem(398);

        .banner__image-wrapper {

            &::after {
                left: pxToRem(7);
            }
        }

        .banner__image-triangle {
            left: pxToRem(-740);
        }

        .banner__image {
            height: pxToRem(398);
        }

        .banner__subheading {
            margin-top: pxToRem(12);
        }
    }

    &::before {
        border-width: 0 pxToRem(80) pxToRem(170);
        left:         pxToRem(-80);
    }

    .banner__heading {
        font-size:   pxToRem(60);
        line-height: 1.2;
    }

    .banner__subheading {
        font-size:  pxToRem(20);
        margin-top: pxToRem(24);
    }

    .banner__image-wrapper {

        &::after {
            border-width: 0 pxToRem(92) pxToRem(194);
            left:         pxToRem(90);
        }

        &::before {
            right: pxToRem(-476);
        }
    }

    .banner__image-triangle {
        left: pxToRem(-656);
    }

    .banner__image {
        height: pxToRem(580);
    }
}
