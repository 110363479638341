/* Module: S */
.lga-selector {
    padding-bottom: 0;
    &__content-wrapper {
        display: flex;
        max-width: pxToRem(1232);
        margin-right: auto;
        margin-left: auto;
        padding: 40px 20px;
    }

    .text-box {
        width: 35%;
    }  

    .right-box {
        width: 48%;
        margin-left: 5%;
        &__search-wrapper {
            float: right;
        }
        &__planning-scheme {
            float: right;
            margin-left: 0;
        }
    }
}

// lga form popup
.lga-popup-container {
    width: pxToRem(530);
    right: 40%;
    top: 90%;
    margin-top: 0;
}