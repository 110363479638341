/* Module: S */
.search-results__table-view,
.search-results__cards-view {

    .search-results__footer {
        margin-top: pxToRem(51);
    }

}

.search-results__table-view {

    .table-view__header {
        width:         100%;
        margin-top:    0;
        margin-bottom: pxToRem(30);
        font-size:     0;
        display:       flex;
        border-top:    pxToRem(1) solid $from-border-grey;
        border-bottom: pxToRem(1) solid $planning;


        .result-row__cell {
            color:          $corporate-services;
            display:        inline-block;
            font-size:      pxToRem(11);
            font-weight:    600;
            letter-spacing: pxToRem(0.2);
            line-height:    pxToRem(15);
            padding:        pxToRem(10) pxToRem(18) pxToRem(10) pxToRem(10);

            span {
                text-transform: uppercase;
                position:       relative;
                top:            50%;
                transform:      translateY(-50%);
                float:          left;
                pointer-events: none;
             }

        }

    }

    .result-row__cell {
        width: 12% !important;

        &.result-cell__prj-name--project {
            width: 20% !important;
        }

        &.result-cell__ref-num--first {
            width: 8% !important;
        }

        &.result-cell__links {
            width: 16% !important;
        }

        &.result-cell__title--consultations {
            width: 18% !important;
        }
        
        &.result-cell__links--consultations,
        &.consultations--links {
            width: 14% !important;
        }

        // &.result-cell__links--project,
        &.result-cell__links--amendments,
        &.result-cell__links--amendments-interim {
            width: 16% !important;
        }

        &.result-cell__date--consultations {
            width: 10% !important;
        }

        &.result-cell__status--consultations {
            width: 9% !important;
        }

        &.result-cell__status-date--consultations {
            width: 14% !important;
        }

        &.result-cell__outcome--consultations {
            width: 14% !important;
        }

        &.result-cell__brief--consultations {
            width: 28% !important;
        }

        &.result-cell__link--panels {
            width: 18% !important;
        }

        &.result-cell__brief--panels {
            width: 47% !important;
        }

        &.result-cell__title--panels {
            width: 24% !important;
        }

        &.result-cell__ref-dec--amendments,
        &.result-cell__brief--amendments-interim {
            width: 36.08% !important;
        }
        &.result-cell__links--amendments,
        &.result-cell__links--amendments-interim {
            a {
                border-top: none;
                padding: 0.625rem 0.5625rem 0.9375rem 0.625rem;
            }
        }
        // ministerial permits
        &.result-cell__title--minister-permits {
            width: 11.5% !important;
        }
        &.result-cell__brief--minister-permits {
            width: 35% !important;
        }
        &.result-cell__outcome--minister-permits {
            width: 16.3% !important;
            position: relative;
        }
        &.result-cell__links--minister-permits {
            width: 14% !important;
        }
    }

    .result-row {
        display:       flex;
        width:         100%;
        font-size:     0;
        border:        pxToRem(1) solid $silver1;
        border-bottom: none;
        padding:       0;
        margin:        0;

        &:last-of-type {
            border-bottom: pxToRem(1) solid $silver1;
        }

        &:nth-of-type(2n) {
            .result-row__cell {
                padding:   pxToRem(6) pxToRem(9) pxToRem(19) pxToRem(10);
            }
        }

        &:nth-of-type(2n + 1) {
            background-color: $wild-sand;
            .result-row__cell {
                padding:   pxToRem(10) pxToRem(9) pxToRem(15) pxToRem(10);
            }
        }

        &:hover,
        &:focus {
            position:   relative;
            z-index:    1;
        }

        .result-row__cell {
            border-right: pxToRem(1) solid $silver1;

            &:last-of-type {
                border-right: none;
            }
            a,
            p {
                font-size:   pxToRem(16);
                line-height: pxToRem(24);
                margin:      0;
                color:       $mine-shaft;
                &.ministerial-permits__extra-address {
                    padding-top:  pxToRem(5);
                    //position:   absolute;
                    //bottom:     pxToRem(15);
                }
            }

            h6 {
                display: none;
            }

            &.result-cell__links--consultations,
            &.result-cell__links--project,
            &.result-cell__links--amendments,
            &.result-cell__links--amendments-interim {
                background-color: transparent;
                border-top: none;
                display: flex;
                justify-content: center;
                position: relative;
                text-align: center;

                a {
                    align-items: center;
                    display: flex;
                }
            }

            &.result-cell__date--consultations span {
                display: block;
            }

            &.result-cell__title--consultations,
            &.result-cell__type--consultations,
            &.result-cell__brief--consultations,
            &.result-cell__date--consultations,
            &.result-cell__status--consultations,
            &.result-cell__status-date--consultations,
            &.result-cell__status--panels,
            &.result-cell__status--amendments,
            &.result-cell__type--amendments-interim,
            &.result-cell__type--minister-permits,
            &.result-cell__title--panels,
            &.result-cell__brief--panels,
            &.result-cell__prj-name,
            &.result-cell__prj-name--project,
            &.result-cell__prj-name--amendments,
            &.result-cell__title--amendments-interim,
            &.result-cell__title--minister-permits,
            &.result-cell__proponent,
            &.result-cell__status,
            &.result-cell__ref-num,
            &.result-cell__ref-num--first,
            &.result-cell__ref-dec--secound,
            &.result-cell__ref-dec--amendments,
            &.result-cell__brief--amendments-interim,
            &.result-cell__brief--minister-permits {
                top: 0;
                background: none;
                color:      $planning;

                a,
                p {
                    border: none;
                }
            }
            &.result-cell__title--consultations p,
            &.result-cell__title--panels p,
            &.result-cell__prj-name p,
            &.result-cell__prj-name--project p,
            &.result-cell__prj-name--amendments p,
            &.result-cell__title--amendments-interim p,
            &.result-cell__title--minister-permits {
                font-weight: 600;
                color: $corporate-services;
            }

            &.result-cell__ref-num {
                display: block;
            }

            &.result-cell__type--consultations,
            &.result-cell__status--panels,
            &.result-cell__status--amendments,
            &.result-cell__type--amendments-interim,
            &.result-cell__type--minister-permits,
            &.result-cell__status,
            &.result-cell__ref-num,
            &.result-cell__ref-num--first,
            &.result-cell__ref-dec--secound {
                position: inherit;
            }

            &.result-cell__links {
                position: inherit;
                background: none;
                padding: 0;
            }

            &.result-cell__links a {
                align-items: center;
                border: none;
                color: $planning;
                display: flex;
                font-size: pxToRem(15);
                height: 50%;
                justify-content: center;
                line-height: pxToRem(20);
                padding: pxToRem(12) pxToRem(34) pxToRem(16) pxToRem(38);
                width: 100%;

                &:hover,
                &:focus {
                    color:            $white;
                    background-color: $corporate-services;
                }

                &:first-of-type {
                    border-bottom: pxToRem(1) solid $silver1;
                    padding:       pxToRem(16) pxToRem(34) pxToRem(13) pxToRem(38);
                }
            }
            &.result-cell__links--amendments,
            &.result-cell__links--amendments-interim,
            &.result-cell__links--minister-permits {
                padding: 0;
                a {
                    height: 100%;
                    border-bottom: 0!important;
                }
            }
        }
    }
}

@media screen and (min-width: 1199px) {

    .search-results__cards-view {
        .result-row {
            width:        calc(25% - 20px);
            margin-left:  pxToRem(10);
            margin-right: pxToRem(10);

            &:first-of-type,
            &:nth-of-type(4n+1) {
                margin-left:   0;
            }

            &:last-of-type,
            &:nth-of-type(4n+4) {
                margin-right:  0;
            }
        }
        .search-results-bar {
            border-bottom: 1px solid $corporate-services;
        }
    }
}

/************************/
/* for amendments class */
/************************/

.amendments {
    .search-results__table-view {
        .table-view__header {
            padding: pxToRem(18) 0;    
        }
        .result-row__cell {
            width: 12.08% !important;
            // flex: 1;
            &.result-cell__proponent {
                // flex-grow: 2;
                width: 36.08% !important;
            }
        }
        .result-row__cell:nth-child(2) {
            width: 36.08% !important;
        }
        .result-row {
            .result-row__cell {
                &.result-cell__links {
                    width: 15.7% !important;
                    .details-link {
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                    }
                }
                &.result-cell__prj-name a {
                    font-size: pxToRem(16);
                    font-weight: 600;
                }
            }
        }
    }
    @media screen and (min-width: 1199px) {
        .search-results__cards-view {
            .search-results-bar {
                border-bottom: 1px solid $corporate-services;
            }
        }
    }
}

/************************/
/* right slide content */
/************************/

.content-drawer {
    .content-drawer__header-box &__header-box-text {
        font-size: 20px;
    }
    &__header-box h3 {
        font-size: 35px;
    }
    &__table {
        display: flex;
        flex-wrap: nowrap;
    }
    &__table-item p {
        font-size: 18px;
    }
    &__table-item:nth-last-child(n+2) {
        border-bottom: none;
        border-right: 2px solid rgba(0, 0, 0, 0.25)
    }
    &__address-box p {
        font-size: 18px;
    }
    &__description-box-grey-field {
        padding: 20px 25px;
    }
    // stages
    &__stages-container {
        display: flex;
    }
    &__stages-item-status {
        width: 45%;
        padding-left: 20px;
        padding-right: 30px;
    }
    &__stages-wrapper:nth-child(n+2):after {
        left: 45px;
    }
    &__stages-item-dates .information {
        font-size: 16px;
    }
    .accordion {
        &__target-file-box {
            display: flex;
            &:first-of-type {
                padding-top: 20px;
            }
            &:last-of-type {
                padding-bottom: 20px;
            }
        }
        &__link {
            font-size: 15px;
        }

        &__target-file-box-header {
            width: 45%;
            padding-right: 40px;
            font-size: 14px;
        }
        &__target-file-box-links {
            width: 55%;
            font-size: 14px;

            &.full-width {
                width: 100%;
            }
        }
    }
}
.affected-schemes-list {
    li {
        width: calc(25% - 10px);
        margin: 0 pxToRem(5);
    }
}