/* Module: S */
.sq-form-question .sq-form-error-custom {
    display: none;
}

.sq-form-question.sq-form-question-error .sq-form-error-custom {
    display: block;
    font-size: 0.8rem;
    margin-top: 4px;
    color: red;
}

.sq-form-question.sq-form-question-error .selectric-wrapper {
    padding-bottom: 0;
}
