/* Module: S */
// .main .property-dashboard {
// padding-bottom: 90px;
// }

// container-fluid
.top-bar .container-fluid,
.property-dashboard .container-fluid {
    max-width: 77rem;
    margin: 0 auto;
}

.property-dashboard.amendments {
    .search-header__title {
        font-size: pxToRem(50);
        margin-bottom: pxToRem(7);
    }

    .search-header__change-plnning-btn {
        font-weight: normal;
        margin-top: 0;
    }

    .search-header__content-wrap {
        margin-bottom: pxToRem(60);
    }
}

// gray top-bar with buttons
.top-bar {
    &__buttons-wrapper {
        padding: pxToRem(20) 0;
    }

    &__button {
        color: white;
        padding: 15px 30px 15px 50px;
        font-size: 14px;
        border: none;
        width: auto;

        &--pdf-download,
        &--pdf-property {
            background: #642667;

            &:before {
                background: url(mysource_files/file-pdf--white.svg) no-repeat center center transparent;
                background-size: contain;
                top: 50%;
                transform: translateY(-50%);
                left: 22px;
            }
        }

        &--pdf-property {
            margin-right: 10px;
        }

        &--map,
        &--map-pd,
        &--map-ms {
            background: #201547;
            margin-left: pxToRem(10);

            &:before {
                background: url(mysource_files/map-pin.svg) no-repeat center center transparent;
                background-size: contain;
                top: 50%;
                transform: translateY(-50%);
                left: 22px;
            }

        }

        &--go-to {
            margin-left: auto;
            background: #642667;
            padding: 15px 50px 15px 30px;

            &:before {
                background: url(mysource_files/list-arrow--white.svg) no-repeat center center transparent;
                background-size: contain;
                top: 50%;
                transform: translateY(-50%);
                left: auto;
                right: 22px;
            }
        }

        &--lga-name {
            display: inline;
        }
    }
}

// index content
.property-dashboard {
    &__content {
        padding-top: pxToRem(25);
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    &__item--text {
        width: 50%;
        display: flex;
        flex-wrap: wrap;

        &.underline {
            border: none;
        }

        &.x1tox1 {
            padding-right: pxToRem(16);
        }
    }

    &__item-fullwidth {
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    &__item--column-text {
        width: 50%;
        border: none;

        .property-dashboard__item--text {
            width: 100%;
        }
    }

    &__item--map {
        margin: 0;
        width: 100%;
        height: 500px;
    }

    &__item-header {
        font-size: 18px;
        width: 100%;
        padding-right: 40px;
    }

    &__item-question {
        margin: 6px 0;
        padding-right: 40px;

        .x1tox1 & {
            width: 55%;
        }

        .x1tox2 & {
            width: 33.333333%;
        }

        .x1tox3 & {
            width: 25%;
        }
    }

    &__item-answer {
        margin: 6px 0;
        padding-right: 40px;

        .x1tox1 & {
            width: 45%;
        }

        .x1tox2 & {
            width: 66.666667%
        }

        .x1tox3 & {
            width: 75%;
        }
    }
}