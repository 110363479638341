/* Module: S */
.search-results.resource-library-results {
    .search-results__summary {
        margin-bottom: 0;
    }
}

.resource-library__items {
    margin: pxToRem(15) 0 pxToRem(100);
}


.resource-library__item {
    display: flex;

    &-wrapper-img {
        width: 250px;
        // height: 250px;
        overflow: hidden;
        img {
            display: block;
        }
    }
    &-with-img {
        .resource-library__item-wrapper-content {
            width: calc(100% - 180px);
            padding-right: 100px;
        }
    }
}



