/* Module: S */
.header.design-2018 {
    .delwp__menu-wrap {
        .delwp__menu {
            & > li {
                & > a {
                    font-size: pxToRem(18);
                    padding-bottom: pxToRem(17);
                }
            }
        }
    }

    .header-wrap {
        height:  pxToRem(100);
        padding: 0 pxToRem(420) 0 pxToRem(178);

        .header__logo-wrap {
            width:        pxToRem(420);
            padding-left: pxToRem(8);
            top:          0;

            &:before {
                width:             calc(95% - 5px);
            }

            &:after {
                width:             95%;
            }

            .header__logo {
                background:      url('{{file_dest}}/logo-2018-desktop.svg') no-repeat left top;
                background-size: contain;
                width:           pxToRem(214);
                height:          pxToRem(57);
                position:        relative;
                right:           pxToRem(-16);
                top:             pxToRem(21.5);
                padding-right:   pxToRem(21);
            }

            .delwp__menu-btn {
                width:           pxToRem(198);
                font-size:       pxToRem(15);
                font-weight:     500;
                line-height:     pxToRem(20);
                letter-spacing:  pxToRem(-0.3);
                text-decoration: none;
                padding:         pxToRem(42) pxToRem(35) pxToRem(38) pxToRem(34);
                color:           $white;
                right:           pxToRem(0);

                &:after {
                    left:  auto;
                    right: pxToRem(10);
                    top:   51%;
                }
            }
        }

        .search-box__toggle {
            display: none;
        }

        .header__title-search-wrap {
            display:        inline-flex;
            flex-direction: row;
            flex-grow:      0;
            flex-shrink:    1;
            flex-wrap:      wrap;
            padding:        pxToRem(31) pxToRem(66) pxToRem(26) pxToRem(21);
            position:       relative;
            width:          100%;
            height:         100%;

            .header__site {
                line-height:  pxToRem(30);

                a {
                    font-size: pxToRem(22);
                }
            }

            .header__search {
                display:  inline-block !important;
                position: absolute;
                right:    pxToRem(44);
                left:     auto !important;
                top:      auto !important;
                bottom:   auto !important;
                width:    auto !important;
                height:   100%;

                form,
                .autosuggest-results {
                    width:  pxToRem(247);
                }

                form {
                    display:  block;
                    margin:   0;
                    padding:  0;
                    height:   pxToRem(43);
                    position: relative;

                    label {
                        display: none;
                    }

                    input {
                        width:            100%!important;
                        padding:          pxToRem(10.5) pxToRem(50) pxToRem(10.5) pxToRem(16);
                        border:           none;
                        background-color: $medium-grey;
                        box-sizing:       border-box;
                        -webkit-box-sizing: border-box;
                    }

                    button {
                        visibility: visible;
                        display:         block !important;
                        position:        absolute;
                        top:             50%;
                        right:           pxToRem(13);
                        transform:       translateY(-50%);
                        width :          pxToRem(17);
                        height:          pxToRem(17);
                        background:      url('{{file_dest}}/search-icon.svg') no-repeat center center;
                        background-size: contain;
                        border:          none;
                        padding:         0;

                        &:hover,
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }

        .megamenu__burger-wrap {
            .megamenu__burger {
                width:   pxToRem(178);
                height:  pxToRem(100);
                padding: pxToRem(42) pxToRem(45) pxToRem(36) pxToRem(49);

                &.megamenu__burger-orig:hover,
                &.megamenu__burger-orig:focus {
                    background-color: $corporate-services;
                    color:            $white;
                    outline: inherit;

                    .megamenu__text {
                        text-decoration: none;
                        color:           $white;
                    }
                }

                .megamenu__burger-box,
                .megamenu__text {
                    display: inline-block;
                }

                .megamenu__text {
                    color:       $white;
                    font-size:   pxToRem(16);
                    font-weight: 500;
                    line-height: pxToRem(22);
                    margin-top:  pxToRem(-3);
                    margin-left: pxToRem(10);

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }

        .megamenu__burger-wrap {

            .megemenu__back {
                font-size: pxToRem(17);
                padding:   pxToRem(37) pxToRem(10) pxToRem(37) pxToRem(30);

                &.megemenu__back-arrow {
                    padding-left: pxToRem(63);
                    &:before {
                        left:  25px;
                    }
                }
            }

            &.header-active-class {
                .megamenu__burger {
                    width:         pxToRem(77);
                    padding:       pxToRem(40) pxToRem(27.5);
                    left:          pxToRem(364);
                }

                .megemenu__back {
                    width:  pxToRem(364);
                }
            }
        }
    }

    .megamenu-wrap {
        .megamenu__list,
        .megamenu__list-l2,
        .megamenu__list-l3 {
            top:   pxToRem(99);
        }

        .megamenu__list {

            & > li {
                & > a {
                    padding:        pxToRem(32) pxToRem(30) pxToRem(25) pxToRem(30);
                    font-size:      pxToRem(17);
                    letter-spacing: pxToRem(-0.4);

                    &:hover,
                    &:focus {
                        border-left:  pxToRem(3) solid $valhalla;
                        padding-left: pxToRem(27);
                    }
                }
            }
        }

        .megamenu__list-l2 {
            &.header-active-class {
                z-index: 3;
            }

            & > li {
                border-bottom: 2px solid $wild-sand;

                & > a {
                    padding: pxToRem(30) pxToRem(44) pxToRem(27) pxToRem(30);
                    & > span {
                        font-size:       pxToRem(17);
                        letter-spacing:  pxToRem(-0.4);
                    }

                    & > p {
                        margin-top: pxToRem(7);
                    }
                }

                &:not(.landing-page),
                &:not(.landing-page) {
                    & > a:hover,
                    & > a:focus {
                        background-color: $wild-sand;
                        border-left:      pxToRem(3) solid $valhalla;
                        padding-left:     pxToRem(27);
                    }
                }

                &.has-submenu {
                    & > a:after {
                        right:  pxToRem(21);
                    }
                }
            }
        }

        .megamenu__list-l3 {
            & > li {
                & > a {
                    display: block;
                    padding: pxToRem(29) pxToRem(58) pxToRem(20) pxToRem(39);
                }

                &:not(.landing-page) > a {
                    &:hover,
                    &:focus {
                        background-color: $wild-sand;
                        border-left:      pxToRem(3) solid $valhalla;
                        padding-left:     pxToRem(36);
                    }
                }

                &.landing-page {
                    & > a:hover,
                    & > a:focus {
                        border-left:      pxToRem(5) solid #855487;
                        padding-left:     pxToRem(34);
                    }
                }

                &.current-session {
                    & > span {
                        padding: pxToRem(30) pxToRem(58) pxToRem(37) pxToRem(39);

                        &:after {
                            bottom:       -6px;
                            border-width: 0 16px 14px 16px;
                        }
                    }
                }
            }
        }

        .megamenu__list,
        .megamenu__list-l3 {
            & > li {
                &.has-submenu {
                    & > a:after {
                        right:  pxToRem(25);
                    }
                }
            }
        }

        .megamenu__list-l2,
        .megamenu__list-l3 {
            & > li {
                &.landing-page {
                    &:after {
                        border-width: 0 12px 10px 12px;
                    }

                    & > a {
                        padding: pxToRem(30) pxToRem(44) pxToRem(37) pxToRem(39);
                    }
                }
            }
        }

        .megamenu__list-l2 {
            & > li {
                &.landing-page {
                    & > a {
                        padding: pxToRem(30) pxToRem(44) pxToRem(37) pxToRem(30);

                        &:hover,
                        &:focus {
                            border-left:      pxToRem(5) solid #855487;
                            padding-left:     pxToRem(25);
                        }
                    }
                }
            }
        }        
    }
}
