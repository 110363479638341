/* Module: S */
.inside,
.main {
    &:before,
    &:after {
        content: none !important;
        display: none !important;
    }
}

// 1. TITLE BANNER
.title-banner-2018 {
    border-bottom: pxToRem(1) solid rgba($corporate-services, .31);
    color:         $corporate-services;
    z-index:       10;

    .container-fluid {
        max-width: pxToRem(1232);
    }

    .title-banner__topic-wrapper {
        background-color: rgba($planning, 0.76);
        color:            $white;
        display:          none;

        .title-banner__topic {
            color:       $white;
            font-size:   pxToRem(35);
            font-weight: 500;
            margin:      pxToRem(16) 0;
        }
    }

    .title-banner__navigation {
        background-color: $corporate-services;
        display:          none;

        .title-banner__list {
            @include sq-list-reset();
            height: auto;
            position: relative;
            padding: pxToRem(10) 0 pxToRem(43) 0;

            li {
                padding:       pxToRem(0);
                margin:        pxToRem(0);
                margin-bottom: pxToRem(4);
                display:       flex;

                & > a {
                    width:         calc(100% - 29px);
                    padding:       pxToRem(32) 0 pxToRem(20) 0;
                    border-bottom: pxToRem(1) solid $manatee;
                    line-height:   pxToRem(20);
                    color:         $white;
                    font-size:     pxToRem(15);
                    font-weight:   500;
                    display:       block;
                    text-decoration: none;

                    &.title-banner__link--active {
                        border-bottom:  pxToRem(3) solid $teal;
                        padding-bottom: pxToRem(18);
                        font-weight:    bold;
                        &:after {
                            content: none;
                        }
                    }
                }

                &:hover,
                &:focus {
                    & > a {
                        border-bottom:  pxToRem(3) solid $teal;
                        padding-bottom: pxToRem(18);
                        font-weight:    bold;
                    }
                }
            }
        }
    }

    .breadcrumb {
        margin-top: pxToRem(47);
        padding:    0;

        a {
            display:         inline-block;
            font-size:       pxToRem(14);
            font-weight:     500;
            line-height:     pxToRem(19);
            color:           $corporate-services;
            text-decoration: none;
            padding-right:   pxToRem(15);
            position:        relative;
            letter-spacing:  0;

            &:hover,
            &:focus {
                text-decoration: underline;
            }

            &:after {
                content: "/";
                display: block;
                position: absolute;
                right: pxToRem(4);
                top: 0;
                width: pxToRem(3);
                height: pxToRem(19);
            }

            &:last-of-type {
                &:after {
                    content: none;
                }
            }
        }
    }

    .title-banner__heading-wrapper {
        background-color: $white;
        padding:          pxToRem(30) 0 pxToRem(50);

        .container-fluid {
            padding-left:  8px;
            padding-right: 8px;
        }

        .title-banner__heading {
            font-size:   pxToRem(46);
            font-weight: 600;
            line-height: pxToRem(40);
            margin:      0 0 pxToRem(13);
        }

        .title-banner__descr {
            font-size:   pxToRem(16);
            font-weight: 600;
            margin:      0;
        }
    }

}
// Title Banner on Detailed project page
.project .title-banner{
    background: $wild-sand;
    .breadcrumb {
        margin-top: pxToRem(20);
    }
    .title-banner {
        &__topic-wrapper {
            display:    block;  
        }
        &__topic {
            font-size: pxToRem(22);
            padding: pxToRem(42) 0 pxToRem(14);
        }
        &__heading-wrapper {
            background: $wild-sand;
        }
    }
}

//lhs-nav
.lhs-nav-wrapper-2018 {

    .lhs-nav {
        border: pxToRem(1) solid $planning;
        background-color: $white;
        padding:          pxToRem(28) 0 pxToRem(30) 0;

        .lhs-nav__list {
            & > li {

                a {
                    display: block;
                    color: $corporate-services;
                    font-size: pxToRem(17);
                    font-weight: normal;
                    text-decoration: none;
                    padding: 1.5rem 0 0 0.8125rem;

                    & > span {
                        display: block;
                        padding-bottom: pxToRem(9);
                        padding-left: pxToRem(20);
                        padding-right: pxToRem(36);
                    }

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }

                &.active {
                    position: relative;
                    & > a {
                        & > span {
                            font-weight: 600;
                            border-bottom: pxToRem(1) solid $planning;
                        }
                    }

                    & > .lhs-nav__sublist {
                        display: block;
                    }
                }

                &.has-sublist {
                    position: relative;
                }
            }

            & > li:first-of-type {
                & > a {
                    & > span {
                        padding-top: 0;
                    }
                }
            }

            & > li:last-of-type {
                & > a {
                    & > span {
                        padding-bottom: 0;
                    }
                }
            }
        }

        .lhs-nav__sublist {
            display: none;

            & > li {
                & > a {
                    & > span {
                        font-size:     pxToRem(16);
                        padding-left:  pxToRem(45);
                        padding-right: pxToRem(40);
                    }
                }

                &.has-sublist {
                    position: relative;
                }

                &.active {
                    background-color: $wild-sand1;

                    & > a {
                        & > span {
                            font-size:     pxToRem(16);
                            font-weight:   500;
                            border-bottom: pxToRem(1) solid $planning;
                        }
                    }

                    & > ul {
                        display: block;
                    }
                }

                & > ul {
                    display: none;
                    li {
                        list-style: none;
                        a {
                            font-size:    pxToRem(16);
                            line-height:  pxToRem(22);
                            padding-left: 0;
                            margin-left:  pxToRem(-40);
                            background-color: $alto2;

                            & > span {
                                padding-bottom: pxToRem(9);
                                padding-left: pxToRem(20);
                            }

                            &:hover,
                            &:focus {
                                background-color: $alto;
                            }
                        }
                    }
                }
            }
        }


        li.has-sublist {
            & > .lhs-nav__cta-2018 {
                outline:            0;
                right:              0;
                -webkit-transition: all 0.35s ease;
                -moz-transition:    all 0.35s ease;
                transition:         all 0.35s ease;
                background:         url(mysource_files/chevron--planning.svg) no-repeat center center;
                border:             0;
                width:              pxToRem(43);
                height:             pxToRem(43);
                margin:             0;
                padding:            0;
                position:           absolute;
                right:              0;
                top:                pxToRem(15);
                transform:          rotate(90deg);

                &:focus {
                    //outline:  #000 dotted thin;
                }
            }

            &.active {
                & > .lhs-nav__cta-2018 {
                    transform: rotate(270deg);
                }
            }
        }

        .lhs-nav__sublist {
            li.has-sublist {
                & > .lhs-nav__cta-2018 {
                    top: pxToRem(30);
                }
            }
        }
    }
}


// Detailed project page
.project { 
    .title-banner {
        padding-bottom: pxToRem(30);
    }
}