/* Module: S */
// 1. BLOCKLINKS
.block-links {
    background-color: $gallery;
    color:      $corporate-services;
    padding:    pxToRem(37) pxToRem(9) pxToRem(28) pxToRem(9);
    min-width:  pxToRem(320);
    position:   relative;

    .container-fluid {
        max-width: pxToRem(1024);
        padding:   0 pxToRem(8);
    }

    .link-block {
        padding: 0;
    }

    .block-links__link {
        @include sq-transition(background .35s ease);
        background:        $white;
        color:             inherit;
        margin-bottom:     pxToRem(9);
        padding:           pxToRem(18) pxToRem(63) pxToRem(18) pxToRem(17);
        position:          relative;
        text-decoration:   none;
        width:             100%;
        border:            1px solid $silver;

        &:hover,
        &:focus {
            background: $corporate-services;
            color: $white;

            .block-link__title {
                color: $white;
            }

            &::after {
                background:      url('{{file_dest}}/arrow--right-white.svg') no-repeat center center;
                background-size: cover;
            }
        }

        &::after {
            @include sq-transform(translateY(-50%));
            background:      url('{{file_dest}}/arrow--right.svg') no-repeat center center;
            background-size: cover;
            content:         '';
            display:         inline-block;
            float:           right;
            height:          pxToRem(18);
            position:        absolute;
            right:           pxToRem(18);
            top:             50%;
            width:           pxToRem(27);
        }

        .block-link__title,
        .block-link__desp {
            width:          100%;
            overflow:       hidden;
            text-align:     left;
            margin:         0;
            letter-spacing: -0.4px;
        }

        .block-link__title {
            font-size:   pxToRem(18);
            font-weight: bold;
        }

        .block-link__desp {
            font-size:      pxToRem(14);
            font-weight:    500;
        }
    }
}

.block-links__scroll {
    background: inherit;
    border:     0;
    bottom:     0;
    font-size:  0;
    left:       50%;
    padding:    0;
    position:   absolute;
    transform:  translateX(-50%);
    outline:    0;

    &:before {
        background:    $white;
        border-radius: pxToRem(50) pxToRem(50) 0 0;
        content:       '';
        display:       block;
        height:        pxToRem(30);
        width:         pxToRem(70);
    }

    &:after {
        border-left: 2px solid $planning;
        border-top:  2px solid $planning;
        content:     '';
        height:      pxToRem(12);
        left:        50%;
        position:    absolute;
        top:         50%;
        transform:   rotate(225deg) translate(50%, 0%);
        width:       pxToRem(12);
    }

    &:hover,
    &:focus {

        &:after {
            border-color: $corporate-services;
        }
    }
    // // optional focus effect, not enabled
    // &:focus {
    //     outline: none;
    //     &:before {
    //         background: $corporate-services;
    //     }
    //     &:after {
    //         border-color: white;
    //     }
    // }

}
