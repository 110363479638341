/* Module: S */
.search-results__table-view,
.search-results__cards-view {
    .table-view__header {
        margin-top: pxToRem(20);

        .result-row__cell {
            display: none;
        }
    }

    .search-results__footer {
        margin:     auto;
        margin-top: pxToRem(20);
        overflow:   hidden;

        .search-results__pagination {
            font-size:  0;
            text-align: center;
            position:   relative;
            padding:    0 pxToRem(40);
            li {
                list-style: none;
                display:    inline-block;


                a,
                span {
                    display:         inline-block;
                    width:           pxToRem(40);
                    height:          pxToRem(40);
                    line-height:     pxToRem(40);
                    font-size:       pxToRem(16);
                    color:           $grey;
                    border-radius:   50%;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color:            $white;
                        background-color: rgba($teal, 0.5);
                    }
                }

                &.search-results__pagination--prev,
                &.search-results__pagination--next {
                    position: absolute;
                    top: 0;
                    a {
                        font-size: 0;
                        background: url('{{file_dest}}/chevron--grey.svg') no-repeat center center transparent;
                    }

                    &.search-results__pagination--prev:hover,
                    &.search-results__pagination--prev:focus {
                        a {
                            transform:         rotate(90deg) scale(1.4);
                            -webkit-transform: rotate(90deg) scale(1.4);;
                        }
                    }

                    &.search-results__pagination--next:hover,
                    &.search-results__pagination--next:focus {
                        a {
                            transform:         rotate(-90deg) scale(1.4);
                            -webkit-transform: rotate(-90deg) scale(1.4);;
                        }
                    }

                }

                &.search-results__pagination--prev {
                    left: pxToRem(-10);
                    a {
                        transform:         rotate(90deg);
                        -webkit-transform: rotate(90deg);
                    }
                }

                &.search-results__pagination--next {
                    right: pxToRem(-10);
                    a {
                        transform:         rotate(-90deg);
                        -webkit-transform: rotate(-90deg);
                    }
                }

                &.search-results__pagination--current {
                    a,
                    span {
                        color:            $white;
                        background-color: $teal;
                    }
                }
            }
        }
    }
}

.search-results__table-view,
.search-results__cards-view {

    .result-row {
        width:         100%;
        border:        pxToRem(1) solid $silver1;
        padding-top:   pxToRem(38);
        padding-bottom:pxToRem(70);
        margin-bottom: pxToRem(17);
        position:      relative;

        &:hover,
        &:focus {
            box-shadow: 0 0 12px 0 rgba($grey, 0.7);
        }
        .result-cell__links--amendments,
        .result-cell__links--amendments-interim,
        .result-cell__date-acpt,
        .result-cell__type--amendments-interim,
        .result-cell__type--minister-permits,
        .result-cell__title--amendments-interim,
        .result-cell__title--minister-permits,
        .result-cell__brief--amendments-interim,
        .result-cell__brief--minister-permits,
        .result-cell__outcome--amendments-interim,
        .result-cell__outcome--minister-permits,
        .result-cell__status-date--amendments-interim,
        .result-cell__status-date--minister-permits,
        .result-cell__outcome--amendments,
        .result-cell__status--amendments,
        .result-cell__ref-dec--amendments,
        .result-cell__prj-name--amendments,
        .result-cell__title--consultations,
        .result-cell__type--consultations,
        .result-cell__brief--consultations,
        .result-cell__date--consultations,
        .result-cell__status--consultations,
        .result-cell__status-date--consultations,
        .result-cell__links--consultations,
        .result-cell__links--project,
        .result-cell__outcome--consultations,
        .result-cell__status--panels,
        .result-cell__title--panels,
        .result-cell__brief--panels,
        .result-cell__prj-name,
        .result-cell__prj-name--project,
        .result-cell__ref-num,
        .result-cell__proponent,
        .result-cell__date-ref-dec,
        .result-cell__ref-dec,
        .result-cell__date-acpt,
        .result-cell__ministers-date,
        .result-cell__status,
        .result-cell__links {
            width:         100%;
            padding-left:  pxToRem(20);
            padding-right: pxToRem(25);

            h6,
            p {
                margin: auto;
            }
        }
        .result-cell__ref-dec--amendments,
        .result-cell__brief--amendments-interim,
        .result-cell__prj-name--amendments,
        .result-cell__title--amendments-interim,
        .result-cell__title--minister-permits,
        .result-cell__title--consultations,
        .result-cell__brief--consultations,
        .result-cell__title--panels,
        .result-cell__prj-name,
        .result-cell__prj-name--project,
        .result-cell__proponent {
            background-color: $planning;

            h6,
            a,
            p {
                color: $white;
            }
        }
        .result-cell__ref-dec--amendments,
        .result-cell__brief--amendments-interim,
        .result-cell__prj-name--amendments,
        .result-cell__title--amendments-interim,
        .result-cell__title--consultations,
        .result-cell__title--panels,
        .result-cell__title--minister-permits {

            h6 {
                display: none;
            }

            p {
                padding-bottom: pxToRem(14);
            }

            a {
                display:         block;
                text-decoration: none;
                font-size:       pxToRem(20);
                line-height:     pxToRem(28);
                padding-bottom:  pxToRem(12);
            }
        }

        .result-cell__ref-dec--amendments,
        .result-cell__brief--amendments-interim,
        .result-cell__prj-name--amendments,
        .result-cell__title--amendments-interim,
        .result-cell__title--consultations,
        .result-cell__title--panels {
            a {
                border-bottom:   pxToRem(1) solid $white;
            }
        }

        .result-cell__ref-dec--amendments,
        .result-cell__brief--amendments-interim,
        .result-cell__brief--minister-permits {
            h6 {
                display: block;
                font-size: pxToRem(11);
                text-transform: uppercase;
                letter-spacing: 0.2px;
                margin-bottom: pxToRem(8);
            }
        }

        .result-cell__title--amendments-interim p{
            border-bottom: pxToRem(1) solid white;
            font-size: pxToRem(20);
        }

        .result-cell__title--consultations {
            padding-top: pxToRem(34);

            p {
                border-bottom: pxToRem(1) solid white;
            }
        }

        .result-cell__prj-name--amendments,
        .result-cell__title--amendments-interim,
        .result-cell__title--minister-permits,
        .result-cell__title--panels {
            padding-top: pxToRem(22);
            padding-bottom: pxToRem(10);
        }

        .result-cell__ref-dec--amendments,
        .result-cell__brief--amendments-interim,
        .result-cell__brief--minister-permits {
            padding-top: pxToRem(14);
            padding-bottom: pxToRem(10);
        }

        .result-cell__status--amendments h6,
        .result-cell__status--amendments p,
        .result-cell__type--amendments-interim h6,
        .result-cell__type--amendments-interim p,
        .result-cell__type--minister-permits h6,
        .result-cell__type--minister-permits p,
        .result-cell__status--panels h6,
        .result-cell__status--panels p,
        .result-cell__status h6,
        .result-cell__status p,
        .result-cell__ref-num h6,
        .result-cell__ref-num p {
            display: inline-block;
        }

        .result-cell__prj-name,
        .result-cell__prj-name--project {
            padding-top: pxToRem(34);
            h6 {
                display: none;
            }
            
            a {
                display:         block;
                text-decoration: none;
                font-size:       pxToRem(20);
                line-height:     pxToRem(28);
                border-bottom:   pxToRem(1) solid $white;
                padding-bottom:  pxToRem(12);
            }
        }
        .result-cell__prj-name--project {
            padding-top: pxToRem(50);
        }
        .result-cell__date-acpt,
        .result-cell__outcome--amendments,
        .result-cell__brief--consultations,
        .result-cell__date--consultations,
        .result-cell__status--consultations,
        .result-cell__status-date--consultations,
        .result-cell__outcome--consultations,
        .result-cell__brief--panels,
        .result-cell__proponent,
        .result-cell__date-ref-dec,
        .result-cell__ref-dec,
        .result-cell__date-acpt,
        .result-cell__ministers-date,
        .result-cell__outcome--amendments-interim,
        .result-cell__outcome--minister-permits,
        .result-cell__status-date--amendments-interim,
        .result-cell__status-date--minister-permits {
            h6 {
                text-transform: uppercase;
                font-size:      pxToRem(11);
                font-weight:    600;
                line-height:    pxToRem(15);
                letter-spacing: pxToRem(0.2);
                margin-bottom:  pxToRem(2);
            }

            p {
                font-size:   pxToRem(16);
                line-height: 1.5;
                &.ministerial-permits__extra-address {
                    font-size:  pxToRem(14);
                }
            }
        }

        .result-cell__brief--consultations,
        .result-cell__brief--panels,
        .result-cell__proponent {
            padding-top:    pxToRem(14);
            padding-bottom: pxToRem(16);
        }
        
        .result-cell__outcome--amendments,
        .result-cell__outcome--amendments-interim,
        .result-cell__outcome--minister-permits,
        .result-cell__status-date--amendments-interim,
        .result-cell__status-date--minister-permits {
            padding-top:    pxToRem(14);
            padding-bottom: pxToRem(8);
        }
        
        .result-cell__date-acpt {
            padding-top:    pxToRem(8);
            padding-bottom: 0;
        }

        .result-cell__date--consultations,
        .result-cell__status--consultations {
            padding-top: pxToRem(12);
        }

        .result-cell__links--consultations,
        .result-cell__links--project {
            margin-top: pxToRem(12);
        }
        
        .result-cell__outcome--consultations,
        .result-cell__status-date--consultations {
            padding-top: pxToRem(12);
        }

        .result-cell__date-ref-dec {
            padding-top:    pxToRem(11);
            padding-bottom: pxToRem(6);

            h6 {
                margin-bottom:  pxToRem(3);
            }
        }

        .result-cell__ref-dec {
            padding-top:    pxToRem(7);
            padding-bottom: pxToRem(6);
        }

        .result-cell__date-acpt,
        .result-cell__ministers-date {
            padding-top:   pxToRem(6);
        }

        .result-cell__status--amendments,
        .result-cell__type--amendments-interim,
        .result-cell__type--minister-permits,
        .result-cell__status--panels,
        .result-cell__type--consultations {
            padding: pxToRem(12) pxToRem(15) pxToRem(12) pxToRem(23);
        }

        .result-cell__status,
        .result-cell__ref-num {
            padding: pxToRem(12) pxToRem(15) 0 pxToRem(23);
        }

        .result-cell__type--consultations p {
            display: block;
        }

        .result-cell__status p,
        .result-cell__ref-num p {
            display: inline-block;
        }

        .result-cell__status--amendments,
        .result-cell__type--amendments-interim,
        .result-cell__type--minister-permits,
        .result-cell__type--consultations,
        .result-cell__status--panels,
        .result-cell__status,
        .result-cell__ref-num {
            position:         absolute;
            top:              0;
            background-color: $corporate-services;
            line-height:      pxToRem(15);

            h6,
            p {
                color:          $white;
                font-size:      pxToRem(12);
                letter-spacing: pxToRem(0.2);
                line-height:    pxToRem(15);
            }

            h6 {
                font-weight:    600;
                text-transform: uppercase;

                &:after {
                    content: ":";
                }
            }
        }

        .result-cell__ref-num {
            top: pxToRem(28);
            padding: 0 pxToRem(15) pxToRem(10) pxToRem(23);
        }

        .result-cell__links--consultations,
        .result-cell__links--project {
            &.disabled {
                background: repeating-linear-gradient( 45deg, #ffffff 5px, #ffffff 7px, #e1e1e1 9px, #e1e1e1 9px);

                a {
                    color: #7b7b7b !important;
                    cursor: default;
                    pointer-events: none;
                    text-decoration: none !important;
                }
            }
        }

        .result-cell__links--consultations,
        .result-cell__links--project {
            background-color: $wild-sand;
            border-top: pxToRem(1) solid $silver1;
            bottom: 0;
            padding: pxToRem(14) 0;
            position: absolute;
            text-align: center;
            margin: 0;

            a {
                color: $planning;
                display: block;
                font-size: pxToRem(13);
                line-height: pxToRem(18);
                text-decoration: underline;
            }

            &:hover,
            &:focus {
                background-color: $corporate-services!important;

                a {
                    color: $white;
                }
            }
        }
        
        .result-cell__links--amendments,
        .result-cell__links--amendments-interim,
        .result-cell__links {
            background-color: $wild-sand;
            font-size:        0;
            padding:          0;
            position:         absolute;
            bottom: 0;

            a {
                display:         inline-block;
                color:           $planning;
                text-decoration: underline;
                font-size:       pxToRem(13);
                line-height:     pxToRem(18);
                text-align:      center;
                padding:         pxToRem(16) 0;
                width:           50%;
                border-top:      pxToRem(1) solid $silver1;
                float:           right;

                &.disabled {
                    pointer-events: none;
                    cursor: default;
                    color: #7b7b7b !important;
                    text-decoration: none !important;
                    background: repeating-linear-gradient( 45deg, #ffffff 5px, #ffffff 7px, #e1e1e1 9px, #e1e1e1 9px);
                }

                &:last-of-type {
                    border-right: pxToRem(1) solid $silver1;
                }

                &:hover,
                &:focus {
                    background-color: $corporate-services;
                    color:            $white;
                }
            }

            .resources-link {
                &:after,
                &:before {
                    display: none;
                }
            }
        }

        .result-cell__links--amendments a,
        .result-cell__links--amendments-interim a,
        .result-cell__links--minister-permits a {
            width: 100%;
            &:last-of-type {
                border-right: none;
            }
        }

        .result-cell__links--consultations {
            .resources-link {
                &:after,
                &:before {
                    display: none;
                }
            }           
        }


        //search-2018-project
        .result-cell__ref-num--first {
            position: absolute;
            top: 0;
            background: #201547;
            width: 100%;
            color: white;
            padding-left: 1.25rem;
            padding-right: 1.5625rem;
            padding-bottom: 5px;
            padding-top: 5px;
            h6 {
                margin: 0;
                color: white;
                display: inline-block;
            }
            p {
                margin: 0;
                display: inline-block;
                font-size: 0.6875rem;
            }
        }

        .result-cell__ref-dec--secound {
            position: absolute;
            top: 1.75rem;
            background: #201547;
            color: white;
            padding-left: 1.25rem;
            padding-right: 1.5625rem;
            width: 100%;
            padding-top: 5px;
            padding-bottom: 10px;
            h6 {
                margin: 0;
                color: white;
                text-transform: uppercase;
            }
            p {
                margin: 0;
                font-size: 0.6875rem;
                text-transform: none;
            }
        }
    }
}

.search-results__cards-view { 
    .result-cell__prj-name,
    .result-cell__prj-name--project {
        font-size: 20px;
        &:after {
            content: "";
            height: 1px;
            width: calc(100% - 40px);
        }
    }
}


/************************/
/* right slide content */
/************************/

.content-drawer {
    // header
    &__header-box {
        margin-bottom: 15px;
        h3 {
            margin: 10px 0;
            font-weight: bold;
        }
    }
    // nesting only for rewrite color
    .content-drawer__header-box &__header-box-text {
        font-size: 16px;
        font-weight: 600;
        color: #662869;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    // table
    &__table {
        margin-top: 15px;
        margin-bottom: 20px;
    }
    &__table-item {
        padding: 20px 15px;
        width: 100%;
        p {
            margin-top: 0;
            font-size: 16px;
        }
        &:nth-last-child(n+2) {
            border-bottom: 1px solid rgba(0,0,0,0.25);
        }
        &.item-color--purple {
            background: #652968;
        }
    }
    &__table-item-header {
        font-weight: 600;
        margin-bottom: 2px;
    }

    &__table-item.item-color--purple p {
        color: white;
    }
    // address box
    &__address-box {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #642667;   
        p {
            color: black;
            margin: 0;
            font-size: 16px;
            &.content-drawer__address-box-header {
                margin-bottom: 10px;
                margin-top: 10px;
            }
            &.content-drawer__address-box-phone-number {
                margin-top: 10px;
            }
        }
    }
    &__address-box-header {
        font-weight: bold;
        margin-bottom: 10px;
    }
    &__address-box-phone-number {
        margin-top: 10px;
        color: black !important;
    }
    // description
    &__description-box {
        margin-top: 20px;
        h4 {
            margin-top: 0;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
        }
    }
    &__description-box-grey-field {
        background: #f6f6f6;
        padding: 10px 15px;
    }

    // stages
    &__stages-wrapper {
        margin-bottom: 20px;
        &:first-of-type {
            margin-top: 25px;
        }
        position: relative;
        &:nth-child(n+2):after {
            content: "";
            position: absolute;
            top: -20px;
            left: 25px;
            display: block;
            width: 2px;
            height: 20px;
            background: #d1d1d1;
        }
    }
    &__stages-item {
        background-color: #ffffff;
        border: solid 1px #c8c8c8;
        &.opened {
            -webkit-box-shadow: 0px 0px 6px 0px rgba(111,111,111,0.7);
            -moz-box-shadow: 0px 0px 6px 0px rgba(111,111,111,0.7);
            box-shadow: 0px 0px 6px 0px rgba(111,111,111,0.7);
        }
        .status--disabled & {
            cursor: default;
            background: repeating-linear-gradient(45deg, #ffffff 5px, #ffffff 7px, #e1e1e1 9px, #e1e1e1 9px);
        }
    }
    &__stages-container {
        padding: 10px;
    }
    &__stages-item-status {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 15px;
        margin-top: 5px;

        .status-name {
            margin-top: 0;
            margin-left: 10px;
            font-size: 16px;
            font-weight: bold;
            color: black;
        }
        .tick {
            .status & {
                display: block;
                width: 35px;
                height: 35px;
                background: url(mysource_files/tick-none.svg) no-repeat center center transparent;
                background-size: contain;
            }
            .status--approved & {
                display: block;
                width: 35px;
                height: 35px;
                background: url(mysource_files/tick-approved.svg) no-repeat center center transparent;
                background-size: contain;
            }
            .status--disabled & {
                display: block;
                width: 35px;
                height: 35px;
                background: url(mysource_files/tick-disabled.svg) no-repeat center center transparent;
                background-size: contain;
            }
        }
    }
    &__stages-item-dates {
        margin: 10px 0;
        .information {
            font-size: 15px;
            font-weight: 600;
            span {
                font-weight: 400;
            }
        }
    }
    &__stages-accordion {
        padding: 0;
        background: #652a67;
        overflow: hidden;

        .accordion {
            &__item {
                margin-bottom: 0;
            }
            &__link {
                color: white;
                text-align: right;
                font-weight: normal;
                padding: 15px 45px 15px 20px;
                font-size: 13px;
                &:after {
                    background: url(mysource_files/chevron.svg) no-repeat center center transparent;
                    right: 15px;
                }
            }
            &__link.uber-accordion__button-active {
                background: #652a67;
            }
            &__target {
                background: #f6f6f6;
            }
            &__target-description {
                font-size: 13px;
            }
            &__target-file-box-header {
                font-weight: 600;
                font-size: 14px;
            }
            &__target-file-box {
                padding: 20px 0;
                &:first-of-type {
                    padding-top: 0;
                }
                &:last-of-type {
                    padding-bottom: 0;
                }
            }
            &__target-file-box-links  {
                font-size: 14px;
            }
            &__target-file-box:nth-last-child(n+2) {
                border-bottom: 1px solid #979797;
            }

        }
    }
}
.affected-schemes-accordion {
    .accordion__link {
        text-align: left;
    }
}
.affected-schemes-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    li {
        width: 50%;
    }
}