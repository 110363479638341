/* Module: S */
html.no-scroll,
html.no-scroll body {
    overflow: hidden;
    position:relative;
    height:100%;
}

// 1. HEADER
.header.design-2018 {
    background-color: $white;
    position:         relative;
    padding:          0;
    z-index:          11;

    .container-fluid,
    .row {
        padding: 0;
        margin:  0;
    }

    .container-fluid {
        min-width: pxToRem(320);
    }

    .delwp__menu-wrap {
        display:          none;
        position:         relative;
        top:              0;
        background-color: $valhalla;
        padding:          0;

        .delwp__home {
            padding:  pxToRem(33) pxToRem(141) pxToRem(31) pxToRem(48);
            position: relative;
            top:      pxToRem(1);

            &:before {
                width:             92%;
                height:            pxToRem(86);
                content:           '';
                position:          absolute;
                top:               pxToRem(0);
                left:              -5%;
                -webkit-transform: skew(145deg);
                   -moz-transform: skew(145deg);
                     -o-transform: skew(145deg);
                        transform: skewX(145deg);
                background-color:  $haiti;
            }

            & > a {
                color:           $white;
                font-size:       pxToRem(16);
                font-weight:     500;
                line-height:     pxToRem(22);
                letter-spacing:  pxToRem(-0.3);
                position:        relative;
                text-decoration: none;
            }
        }

        .delwp__menu {
            display:        flex;
            flex-direction: row;
            flex-grow:      0;
            flex-shrink:    1;
            flex-wrap:      wrap;
            padding:        pxToRem(30) 0 pxToRem(72) pxToRem(40);
            margin:         0;

            & > li {
                list-style:   none;
                margin-right: pxToRem(-5);
                margin-bottom: pxToRem(8);
                & > a {
                    display:         block;
                    border-bottom:   pxToRem(1) solid $gray91;
                    padding:         pxToRem(23) pxToRem(0);
                    width:           calc(100% - 10px);
                    color:           $white;
                    text-decoration: none;
                    font-size:       pxToRem(15);
                    font-weight:     400;
                    letter-spacing:  pxToRem(-0.4);
                    line-height:     pxToRem(20);
                }

                &:hover,
                &:focus {
                    & > a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .header-wrap {
        height:   pxToRem(70);
        padding:  pxToRem(18) pxToRem(0) pxToRem(17) pxToRem(0);

        .header__logo-wrap,
        .header__site {
            display: inline-block;
        }

        .header__logo-wrap {
            border-right: pxToRem(1) solid $alto;
            padding:      pxToRem(0) pxToRem(11) pxToRem(0) pxToRem(9);

            .header__logo {
                background:      url('{{file_dest}}/logo-2018-mobile.svg') no-repeat center center;
                background-size: contain;
                display:         inline-block;
                height:          pxToRem(35);
                width:           pxToRem(61);

                &:hover,
                &:focus {
                    outline: none;
                }
            }

            .delwp__menu-btn {
                display: none;
            }
        }

        .search-box__toggle {
            display:         block;
            font-size:       0;
            width :          pxToRem(70);
            height:          pxToRem(70);
            background:      url('{{file_dest}}/search-icon.svg') no-repeat center center;
            background-size: 19.4px 19.4px;
            border:          none;
            padding:         pxToRem(25);
            position:        absolute;
            z-index:         19;
            top:             50%;
            transform:       translateY(-50%);
            right:           pxToRem(70);

            &.search-box__close {
                background:  $concrete url('{{file_dest}}/cancel-2018.svg') no-repeat center center;
                background-size: 19.4px 19.4px;
            }


            &:hover,
            &:focus {
                outline: none;
            }
        }

        .header__title-search-wrap {
            display:      inline-block;
            width:        100%;
            padding-left: pxToRem(90);
            position:     absolute;

            .header__site {
                line-height:     pxToRem(24);
                vertical-align:  top;
                padding:         pxToRem(7) pxToRem(9) 0 pxToRem(4);

                & > a {
                    text-decoration: none;
                    font-size:       pxToRem(16);
                    font-weight:     bold;
                    color:           $valhalla;
                }
            }

            .header__search {
                display: none;
                background: transparent;

                & > form {
                    label,
                    button {
                        display:    none;
                        font-size:  0;
                        visibility: hidden;
                    }
                }
                
                &.header__search--active {
                    position:         absolute;
                    z-index:          19;
                    left:             0;
                    top:              pxToRem(52);
                    width:            100%;
                    height:           auto;
                    background-color: $concrete;
                    color:            $white;

                    form {
                        display: block;
                        
                        label {
                            display: none;
                        }

                        input {
                            width:            calc(100% - 32px);
                            padding:          pxToRem(13) pxToRem(16) pxToRem(15) pxToRem(16);
                            background-color: $concrete;
                            color:            $corporate-services;
                            font-size:        pxToRem(16);
                            line-height:      pxToRem(22);
                            border:           none;

                            &:hover,
                            &:focus {
                                outline: none;
                            }
                        }

                        input::placeholder {
                            color:            $corporate-services;
                        }

                        button[type=submit] {
                            display: none;
                        }

                        #autocomplete-results {
                            width: 100%;
                        }
                    }
                }

                .header__menu-burger,
                .header__search-icon {
                    display: none !important;
                }

                .header__search-icon-box {
                    display: none !important;
                }
            }
        }

        .megamenu__burger-wrap {
            overflow-y: hidden;
            .megemenu__back {
                display:          none;
                background-color: $planning;
                position:         absolute;
                z-index:          20;
                right:            pxToRem(70);
                top:              0;
                font-weight:      500;
                font-size:        pxToRem(15);
                color:            $white;
                letter-spacing:   pxToRem(-0.3);
                width:            0;
                line-height:      pxToRem(26);
                text-decoration:  none;
                padding:          pxToRem(23) pxToRem(10) pxToRem(21) pxToRem(10);
                transition:       width 0.5s;

                &.megemenu__back-arrow {
                    display:             none;
                    padding-left:        pxToRem(42);
                    &:before {
                        display:         block;
                        content:         '';
                        position:        absolute;
                        top:             50%;
                        left:            10px;
                        width:           pxToRem(20);
                        height:          pxToRem(13);
                        background:      url('{{file_dest}}/chevron.svg') no-repeat center center;
                        background-size: cover;
                        transform:       translateY(-50%) rotate(90deg);
                    }

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }

            .megamenu__burger {
                background-color: $planning;
                width:            pxToRem(70);
                height:           pxToRem(70);
                padding:          pxToRem(27) pxToRem(22) pxToRem(26) pxToRem(25);
                position:         absolute;
                right:            pxToRem(0);
                top:              0;
                z-index:          20;
                text-decoration:  none;
                .megamenu__burger-inner {
                    width:           pxToRem(20);
                    height:          pxToRem(20);
                    background:      url('{{file_dest}}/menu-white.svg') no-repeat center center;
                    background-size: cover;
                }

                .megamenu__text {
                    display: none;
                }
            }

            &.header-active-class {
                .megemenu__back {
                    display:  block;
                    width:    calc(100% - 70px);
                    height:   100%;

                    & > span {
                        display:     inline-block;
                        height:      pxToRem(34);
                        padding-top: pxToRem(2);
                        overflow:    hidden;
                    }

                    &.megemenu__back-arrow {
                        display:             none;
                    }
                }

                .megamenu__burger {
                    background-color: $white;
                    padding:          pxToRem(25) pxToRem(25);
                    .megamenu__burger-inner {
                        background: url('{{file_dest}}/cancel-2018.svg') no-repeat center center;
                    }

                    &.megamenu__close {
                        .megamenu__text {
                            font-size:  pxToRem(0);
                            color:      $white;
                            display:    none;
                        }
                    }

                    &.megamenu__close:hover,
                    &.megamenu__close:focus {
                        background-color: $planning;
                        .megamenu__burger-inner {
                            background: url('{{file_dest}}/cancel-2018--white.svg') no-repeat center center;
                        }
                    }
                }
            }
        }
    }

    .megamenu-wrap {
        height: 0;

        ul, p {
            margin: 0;
        }

        li {
            list-style: none;
        }

        a {
            text-decoration: none;
        }

        .megamenu__list,
        .megamenu__list-l2,
        .megamenu__list-l3 {
            background-color: $white;
            width:            0;
            padding:          0;
            position:         fixed;
            top:              pxToRem(70);
            bottom:           0;
            right:            0;
            z-index:          -1;
            opacity:          0;
            transition:       width 0.1s;
            overflow-y:       hidden;
            visibility:       hidden;

            &.header-current-class {
                overflow-y:   auto;
            }

            &.header-active-class {
                width:   100%;
                opacity: 20;
            }

            & > li.page-selected {
                background-color: $alto;
                position: relative;
                z-index: 1;
            }

        }

        .megamenu__list {
            &.header-active-class {
                z-index:    21;
                visibility: visible;
            }

            & > li {
                border-bottom: 1px solid rgba($planning, 0.28);

                &:last-of-type {
                    border-bottom: none;
                }

                & > a {
                    display:         block;
                    padding:         pxToRem(30) pxToRem(30) pxToRem(30) pxToRem(10);
                    font-size:       pxToRem(18);
                    font-weight:     500;
                    line-height:     pxToRem(20);
                    color:           $valhalla;
                    position:        relative;

                    &:hover,
                    &:focus {
                        background-color: $wild-sand;
                        border-left:      pxToRem(5) solid $valhalla;
                        padding-left:     pxToRem(5);
                    }
                }
            }
        }

        .megamenu__list-l2 {
            &.header-active-class {
                z-index:    22;
                visibility: visible;
            }

            & > li {
                border-bottom: 2px solid rgba($planning, 0.28);
                position:      relative;

                &:last-of-type {
                    border-bottom: none;
                }

                & > a {
                    padding: pxToRem(12) pxToRem(44) pxToRem(22) pxToRem(12);
                    display: block;
                    & > span {
                        display:        block;
                        font-size:      pxToRem(16);
                        font-weight:    600;
                        line-height:    pxToRem(26);
                        color:          $valhalla;
                        letter-spacing: pxToRem(-0.3);
                    }

                    & > p {
                        margin-top:     pxToRem(2);
                        font-size:      pxToRem(13);
                        line-height:    normal;
                        letter-spacing: pxToRem(-0.3);
                        color:          $valhalla;
                    }
                }

                &:not(.landing-page),
                &:not(.landing-page) {
                    & > a:hover,
                    & > a:focus {
                        background-color: $wild-sand;
                        border-left:      pxToRem(5) solid $valhalla;
                        padding-left:     pxToRem(7);
                    }

                    & > a:active {
                        color: $valhalla;
                    }
                }

                &.landing-page {
                    & > a:hover,
                    & > a:focus {
                        text-decoration:  none;
                        border-left:      pxToRem(5) solid #855487;
                        padding-left:     pxToRem(7);
                    }
                }

            }
        }

        .megamenu__list-l3 {
            &.header-active-class {
                z-index:    23;
                visibility: visible;
            }

            & > li {
                border-bottom: 1px solid rgba($planning,0.28);

                &:last-of-type {
                    border-bottom: none;
                }

                & > a,
                & > span {
                    display:        block;
                    padding:        pxToRem(21) pxToRem(44) pxToRem(29) pxToRem(12);
                    font-size:      pxToRem(16);
                    font-weight:    500;
                    line-height:    pxToRem(24);
                    color:          $valhalla;
                    letter-spacing: pxToRem(-0.4);
                    position:       relative;
                }

                &:not(.landing-page) {
                    & > a:hover,
                    & > a:focus {
                        background-color: $wild-sand;
                        border-left:      pxToRem(5) solid $valhalla;
                        padding-left:     pxToRem(7);
                    }
                }

                &.landing-page {
                    & > a:hover,
                    & > a:focus {
                        border-left:      pxToRem(5) solid #855487;
                        padding-left:     pxToRem(7);
                    }
                }

                &.current-session {
                    background-color: $valhalla;
                    color:            $white;

                    & > span {
                        display:         block;
                        color:           $white;
                        text-decoration: none;
                        pointer-events:  none;
                        cursor:          default;
                        position:        relative;

                        &:after {
                            display:      block;
                            content:      '';
                            position:     absolute;
                            left:        50%;
                            bottom:       -1px;
                            width:        0;
                            height:       0;
                            border-style: solid;
                            border-width: 0 20px 16px 20px;
                            border-color: transparent transparent #ffffff transparent;
                            transform:    translateX(-50%);
                        }
                    }
                }
            }
        }

        .megamenu__list,
        .megamenu__list-l2,
        .megamenu__list-l3 {
            & > li {
                width: 100%;

                & > a {
                    display: block;
                }
                &.has-submenu {
                    & > a {
                        padding-right: pxToRem(44);
                    }
                    & > a:after {
                        display:         block;
                        content:         '';
                        position:        absolute;
                        top:             50%;
                        right:           pxToRem(18);
                        width:           pxToRem(9);
                        height:          pxToRem(15);
                        background:      url('{{file_dest}}/chevron--planning.svg') no-repeat center center;
                        background-size: cover;
                        transform:       translateY(-50%);
                    }
                }
            }
        }

        .megamenu__list-l2,
        .megamenu__list-l3 {
            & > li {
                &.landing-page {
                    border-bottom:    none;
                    background-color: $valhalla;
                    color:            $white;
                    position:         relative;

                    &:after {
                        display:      block;
                        content:      '';
                        position:     absolute;
                        left:        50%;
                        transform:    translateX(-50%);
                        bottom:       -2px;
                        width:        0;
                        height:       0;
                        border-style: solid;
                        border-width: 0 20px 16px 20px;
                        border-color: transparent transparent #ffffff transparent;
                    }

                    & > a {
                        padding: pxToRem(23) pxToRem(44) pxToRem(38) pxToRem(12);
                        color:   $white;
                        & > span,
                        & > p {
                            color: $white;
                        }

                        & > p {
                            text-decoration: none;
                            margin-top:      pxToRem(7);
                        }
                    }
                }
            }
        }
    }
}
