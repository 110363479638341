/* Module: S */
.planning-scheme-opened #main {
    z-index: 1;
}
.scroll-y-content--mobile {
    height: inherit;
    overflow-y: inherit;
}

body.planning-scheme-opened {
    overflow: inherit;
}
.planning-scheme__search-wrapper {
    &>form {
        padding: 30px 25px 10px 0;
    }
    .search__input {
        border-bottom: 0.125rem solid rgba(32, 21, 71, 0.5);
    }
}

.search-header-right-buttons {
    display: block;
    position: absolute;
    top: 35px;
    right: 0;
    .lodge-amendment-button {
        float: right;
        font-size: 13px;
        text-decoration: none;
        color: white;
        padding: 10px 20px;
        background: #642667;
        &:after {
            background: url(mysource_files/drawer_link_icon2--white.svg) center center no-repeat transparent;
            background-size: cover;
            content: '';
            display: inline-block;
            height: 18px;
            margin: 0 5px;
            opacity: 1;
            position: relative;
            visibility: visible;
            top: 4px;
            width: 18px;
        }
    }
}

.planning-scheme {
    position: relative;
    .background-mask--left {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #f6f6f6;
    }
    &>.container-fluid {
        max-width: 77rem;
    }
    &__wrapper {
        display: flex;
    }
    &__content-list {
        width: 35%;
    }
    &__content-box {
        width: 65%;
        background-color: white;
        padding: 0 20px;
        height: inherit;
        position: inherit;
        z-index: inherit;
        top: inherit;
        right: inherit;

        .mobile-close-bar {
            display: none;
        }

        .top-bar {
            display: flex;
            justify-content: space-between;
            margin: 20px 0;
            &__breadcrumbs {
                display: block;
                width: 50%;
                &--first,
                &--second,
                &--third {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    font-size: 13px;
                    color: $valhalla;
                }
                &--first {
                    font-weight: 600;
                }
                &--second {
                    margin-left: 25px;
                }
                &--third {
                    margin-left: 50px;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                &--second:after,
                &--third:after {
                    content: "";
                    background: url(mysource_files/chevron--planning.svg) no-repeat center center transparent;
                    width: 12px;
                    height: 12px;
                    display: block;
                    position: absolute;
                    background-size: contain;
                    left: -15px;
                    top: calc(50% - 6px);
                }

            }
            &__buttons {
                margin: 12px 10px;
                a {
                    padding: 10px 20px 10px 40px;
                    margin-left: 0;
                }
                &--print-button:after,
                &--view-map-button:after {
                    left: 15px;
                }
            }
        }
        .content-box {
            display: flex;
            margin: 0px 10px;
            &__numbers-wrapper {
                flex-wrap: wrap;
                display: inline-block;
                margin-right: 60px;
                margin-top: 15px;
                &--counter {
                    font-size: 35px;
                    margin-top: -20px;
                }
                &--date,
                &--symbol {
                    margin: 0;
                    font-size: 14px;
                }
            }
            &__content-wrapper {
                &-heading {
                    &--purple {
                        font-size: 20px;
                    }
                    &--dark-blue {
                        font-size: 18px;
                    }
                }
                h1 {
                    font-size: 35px;
                    margin: 0;
                }
            }
        }
    }
    &.full-width {
        .planning-scheme__content-list {
            width: 100%;
        }
        .planning-scheme__content-desc {
            display: flex;
            padding: pxToRem(20) 0;
        }
        .planning-scheme__content-desc-text {
            margin-right: pxToRem(60);
            width: 55%;
        }
        .planning-scheme__content-desc-pdf {
            width: 45%;
        }

        .side-menu {
            .accordion__l1-button {
                font-size: pxToRem(20);
            }
            .accordion__l1-button-counter {
                margin-right: pxToRem(35);
            }
            .accordion__l2-link-counter {
                margin-right: pxToRem(55)
            }
            .accordion__l2-link {
                font-size: pxToRem(18);
            }
            .accordion__l3-link {
                font-size: pxToRem(16);
            }
            .accordion__l4-link {
                font-size: pxToRem(16);
            }
            .accordion__l2 {
                &:after {
                    right: 0;
                }
            }
            .accordion__l2-content .accordion__l2-link {
                margin-left: pxToRem(100);
            }
            .accordion__l2-content .accordion__l2-link-counter {
                min-width: pxToRem(160);
            }
        }
    }
}

.planning-scheme.full-width.planning-scheme-map {
    .planning-scheme__content-map {
        padding: pxToRem(70) 0;
        &-enlargement {
            width: pxToRem(307);
        }
    }
    .side-menu {
        .accordion__l2-date {
            right: pxToRem(50);
        }
    }
}
// PLANNING SCHEME HISTORY
.planning-scheme__history {
    padding: pxToRem(30) 0;
}

.side-menu {
    &-reset-button {
        padding-right: 25px;
    }
    .accordion {
        &__link {
            background-color: #f6f6f6;
        }

        &__l2:after,
        &__l3:after,
        &__l4:after {
            width: calc(100% - 70px);
            right: 10px;
        }

        &__l2-link,
        &__l3-link {
            margin-left: 100px;
            padding-right: 10px
        }
        &__l4-link {
            margin-left: 60px;
            padding-right: 10px
        }

        &__l2-button,
        &__l3-button {
            left: 60px;
        }
    }
}

.pdf-link--box__wrapper {
    border-bottom: none;
}

.social-media-inline-text {
    font-size: 16px;
}