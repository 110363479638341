/* Module: S */
.tabs.browse-tabs {
    padding: pxToRem(40) pxToRem(20);
    .tabs__tab-list-wrapper {
        padding: 0 pxToRem(20);
        &:before {
            margin: 0 pxToRem(20);
        }
    }
    .lga-selector__content-wrapper {
        padding: pxToRem(40) pxToRem(20);
    }
    .lga-selector .right-box__search-wrapper {
        margin-top: 0;
    }
    .tabs__item:first-child .tabs__link {
        margin-right: pxToRem(55);
    }
    .tabs__link {
        font-size: pxToRem(20);
    }
}