/* Module: S */
.carousel {
	h1 {
		margin-bottom: 	pxToRem(32);
		font-size: 		pxToRem(45);
	}
}

.carousel__mobi-only {
	display: none;
}

.carousel__content {
	top: 129px;
}

a.carousel__link {

	padding: pxToRem(10) pxToRem(44);
}

.carousel__item {
	height: pxToRem(583);
}

ul.carousel-nav {
	display: flex;
	padding: 0 pxToRem(20);

	li {
		width: 			31%;
		margin: 		0 1%;
		vertical-align: top;
		cursor: 		pointer;

		a {
			text-align: left;
			box-shadow: inset 0 2px 0 0 white;
			-webkit-transition: .2s;
					transition: .2s;

			&:hover, &:focus {
				box-shadow: inset 	0 3px 0 0 $teal;
				-webkit-transition: .2s;
				transition: 		.2s;
			}
		}

		&.slick-active {

			a {
				box-shadow: inset 0 3px 0 0 $teal;
			}
		}
	}

	h3 {
		font-size: 		pxToRem(16);
		text-transform: uppercase;
		margin: 		0 0 pxToRem(8) 0;
		padding-top: 	pxToRem(16);
	}
}

a.carousel-nav__item {
	display: 			block;
	height: 			100%;
	text-decoration: 	none;

	p {
		margin-top: 0;
		font-size: 	pxToRem(14);
	}
}

.carousel .slick-arrow {
	position: 	absolute;
	background: transparent;
	top: 		45%;
	z-index: 	2;
	border: 	none;
	background: initial;
	font-size: 	0;
	padding: 	0;
	width: 		pxToRem(44);
	height: 	pxToRem(44);

	&:before, &:after {
		@include sq-transition(background-color 0.2s linear);
	}

	&:before {
		content: 		'';
		position: 		absolute;
		display: 		block;
		width: 			pxToRem(44);
		height: 		pxToRem(44);
		border: 		2px solid;
		border-color: 	white;
		border-radius: 	pxToRem(50);
	}

	&:after {
		content: 		'';
		position: 		absolute;
		display: 		block;
		border-top: 	2px solid;
		border-right: 	2px solid;
		border-color: 	white;
		height: 		pxToRem(12);
		width: 			pxToRem(12);
	}

	&:focus:before, &:hover:before {
		@include sq-transition(background-color 0.2s linear);
		background-color: white;
	}

	&:focus:after, &:hover:after {
		border-color: black;
	}

	&.slick-prev {
		outline: 0;
		left: 	 2%;

		@media screen and (min-width: 1500px) {
			left: 5%;
		}

		&:before {
			top: 0;
		}

		&:after {
			transform: 	rotate(225deg);
			top: 		pxToRem(16);
			left: 		pxToRem(18);
		}
	}

	&.slick-next {
		outline: 0;
		right: 	 2%;

		@media screen and (min-width: 1500px) {
			right: 5%;
		}

		&:after, &:before {
			right: 	0;
			top: 	0;
		}

		&:after {
			transform: 	rotate(45deg);
			top: 		pxToRem(16);
			right: 		pxToRem(18);
		}
	}
}