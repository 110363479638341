/* Module: S */
.carousel__item {
    background-position: center;
    height:              pxToRem(486);

    &.carousel__item--top {
        background-position: top;
    }

    &.carousel__item--bottom {
        background-position: bottom;
    }
}



.carousel__content {
    top: 145px;
}

a.carousel__link {
    padding: pxToRem(14) pxToRem(62);
}

