/* Module: S */
.featured-image {
	&--large,
	&--small {
		background-color: $medium-grey;
		padding:          pxToRem(40) 0;
	&.padding_top--half {
        padding-top: pxToRem(20);
    }

    &.padding_bottom--half {
        padding-bottom: pxToRem(20);
    }
     &.padding_top--no {
        padding-top: 0;
    }
    &.padding_bottom--no {
        padding-bottom: 0;
    }
        .container-fluid {
            max-width: pxToRem(1200);
        }
	}
	&--header {
		color:          $corporate-services;
		display:        block;
		font-size:      pxToRem(24);
		font-weight:    600;
		font-style:     normal;
		font-stretch:   normal;
		letter-spacing: pxToRem(-0.4);
		margin:         0;
		text-align:     center;
	}
	&__container {
		padding-top: pxToRem(20);
	}
	&__content {
		display: flex;
	    flex-flow: column nowrap;

	    .col-xs-12 {
	    	order: 1;
	    }
	    .col-xs-12.col-sm-6 {
	    	order: 2;
	    }

		&-item {
			height:        120px;
			margin-bottom: pxToRem(5);
			padding:       0;
			position:      relative;

			&--link {
				display:         block;
				height:          100%;
				overflow:        hidden;
				padding:         pxToRem(20);
				position:        absolute;
				text-decoration: none;
				width:           100%;
				
				&:active,
				&:hover,
				&:focus {
					outline: none;
				}
				&::after {
					bottom:     pxToRem(20);
					content:    '';
					display:    inline-block;
					height:     pxToRem(16);
					position:   absolute;
					right:      20px;
					width:      pxToRem(25);
				}
			}
		//Label globals styles
			&--label {
				font-size:    pxToRem(12);
				font-weight:  400;
			}
		//Title globals styles
			&--title {
				font-size:    pxToRem(18);
				font-weight:  600;
				line-height:  1.33;
				margin:       pxToRem(5) 0;
			}

///////////////////Item colour and teal//////////////////////////////////
			&--teal,
			&--colour {
				background-color: $teal;

				.featured-image__content-item {
					&--label {
						color: $white;
					}
					&--title {
						color: $white;
					}
					&--link {
		            	@include sq-transition(background-color .6s linear);

		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($teal, 5%);
						}

						// &::after {
						// 	background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
						// }
		            }
				}
			}
			&--teal {
				.featured-image__content-item--link::after {
					background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
				}
			}
			&--colour {
				.featured-image__content-item--link::after {
					background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
				}
			}

///////////////////Item color water-and-catchments/////////////////////
			&--water-and-catchments {
				background-color: $water-and-catchments;

				.featured-image__content-item {
					&--link {
		            	@include sq-transition(background-color .6s linear);

		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($water-and-catchments, 5%);
						}
						&::after {
							background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
						}
		            }
					&--label,
					&--title {
						color: $corporate-services;
					}
				}
			}

///////////////////Item color corporate-services/////////////////////
			&--corporate-services {
				background-color: $corporate-services;

				.featured-image__content-item{
					&--link {
		            	@include sq-transition(background-color .6s linear);

		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($corporate-services, 5%);
						}
						&::after {
							background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
						}
		            }
					&--label,
					&--title {
						color: $white;
					}
				}
			}

///////////////////Item color purple-light///////////////////////////
			&--purple-light {
				background-color: rgba($corporate-services, 0.3);

				.featured-image__content-item {
					&--link {
		            	@include sq-transition(background-color .6s linear);

		            	&:active,
						&:hover,
						&:focus {
							background-color: rgba($corporate-services, 0.1);
						}
						&::after {
							background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
						}
		            }
					&--label,
					&--title {
						color: $corporate-services;
					}
				}
			}

///////////////////Item color purple-dark///////////////////////////
			&--purple-dark {
	            background-color: rgba($corporate-services, 0.7);

	            .featured-image__content-item {
	            	&--link {
		            	@include sq-transition(background-color .6s linear);

		            	&:active,
						&:hover,
						&:focus {
							background-color: rgba($corporate-services, 0.9);
						}
						&::after {
							background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
						}
		            }
	            	&--label,
	            	&--title {
		            	color: $white;
		            }
		        }
	        }

///////////////////Item color planning///////////////////////////
	        &--planning {
	        	background-color: $planning;

				.featured-image__content-item {
					&--link {
		            	@include sq-transition(background-color .6s linear);

		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($planning, 5%);
						}
						&::after {
							background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
						}
		            }
					&--label,
					&--title {
						color: $white;
					}
				}
	        }

///////////////////Large item styles///////////////////////////////       
			&--large {
				background-image:    url('{{file_dest}}/example-image.jpg');
				background-position: center center;
				background-repeat:   no-repeat;
				background-size:     cover;
				height:              pxToRem(240);
				overflow:            hidden;

				.featured-image__content-item {
					&--label {
						position: relative;
						z-index:  2;
					}
					&--title {
						font-size:   pxToRem(20);
						line-height: 1.2;
						position:    relative;
						width:       pxToRem(140);
						z-index:     2;
					}
					&--icon {
						left: 20px;
					}
					&--link {
						&::before {
							@include sq-transition(width 1s ease-in-out);
							border-top:       26rem  solid;
							border-top-color: inherit;
							border-right:     14rem solid transparent;
							content:          '';
							left:             0;
							position:         absolute;
							top:              0;
							width:            calc(100% - 58px);
						}
						&::after {
							@include sq-transition(right 1s ease-in-out);
							bottom:     pxToRem(20);
							content:    '';
							display:    inline-block;
							height:     pxToRem(16);
							right:      190px;
							position:   absolute;
							width:      pxToRem(25);
						}
						&:hover,
						&:focus,
						&:active {
							outline: none;
							background-color: transparent;
							&::before {
								width: 1000px;
							}
							&::after {
								right: 20px;
							}
						}
					}
				}
				&.featured-image__content-item--teal {
					.featured-image__content-item--link {
						border-top-color: $teal;
						&::after {
							background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
						}
					}
				}
				&.featured-image__content-item--corporate-services {
					.featured-image__content-item--link {
						border-top-color: $corporate-services;
						&::after {
							background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
						}
					}
				}
			}
		}
	}

	&--small {
		.featured-image__content-item--purple-dark {

            .featured-image__content-item {
            	&--link::after {
					background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
				}
		            
	            &--label {
		        	color: $white;
	            }
            }
        }
	}
}

.planning,
.environment, 
.climate-change,
.energy,
.local-infrastructure,
.forest-fire,
.water-and-catchments {
	.featured-image {
		&--small,
		&--large {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						border-top-color: $environment;
						&:active,
						&:hover,
						&:focus {
							background-color: transparent;
						}
						&::after {
								background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
							}

						.featured-image__content-item{
							&--label,
							&--title {
								color: $corporate-services;
							}
						}
					}
				}
				&--purple-light {
					background-color: $teal;
					.featured-image__content-item--link {
		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($teal, 5%);
						}
					}
				}
				&--teal {
					background-color: $environment;
					.featured-image__content-item {
						&--link {
			            	&:active,
							&:hover,
							&:focus {
								background-color: darken($environment, 5%);
							}
							&::after {
								background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
							}
			            }
						&--label,
						&--title {
							color: $corporate-services;
						}
					}
				}
			}
		}
		&--small {
			.featured-image__content-item {
				&--purple-light {
					background-color: $teal;

					.featured-image__content-item {
						&--link {

			            	&:active,
							&:hover,
							&:focus {
								background-color: darken($teal, 5%);
							}
							&::after {
								background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
							}
			            }
						&--label,
						&--title {
							color: $corporate-services;
						}
					}
				}
				&--colour {
		        	background-color: $environment;

					.featured-image__content-item {
						&--link {

			            	&:active,
							&:hover,
							&:focus {
								background-color: darken($environment, 5%);
							}
							&::after {
								background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
							}
			            }
						&--label,
						&--title {
							color: $corporate-services;
						}
					}
		        }
			}
		}
	}
}

.planning {
	.featured-image {
		&--small,
		&--large {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						border-top-color: $planning;
						&:active,
						&:hover,
						&:focus {
							background-color: transparent;
						}
						&::after {
							background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
						}
						.featured-image__content-item{
							&--label,
							&--title {
								color: $white;
							}
						}
					}
				}
				&--teal {
					background-color: $planning;
					.featured-image__content-item {
						&--link {
			            	&:active,
							&:hover,
							&:focus {
								background-color: darken($planning, 5%);
							}
							&::after {
								background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
							}
			            }
						&--label,
						&--title {
							color: $white;
						}
					}
				}
			}
		}
		&--small {
			.featured-image__content-item--colour {
				background-color: $planning;

				.featured-image__content-item {
					&--link{
						&:active,
						&:hover,
						&:focus {
							background-color: darken($planning, 5%);
						}
						&::after {
							background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
						}
					}
					&--label,
					&--title {
						color: $white;
					}
	            }
			}
		}
	}
}
.water-and-catchments {
	.featured-image {
		&--small,
		&--large {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						border-top-color: $water-and-catchments;
					}
				}
				&--teal {
					background-color: $water-and-catchments;
					.featured-image__content-item--link {
		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($water-and-catchments, 5%);
						}
		            }
				}
			}
		}
		&--small {
			.featured-image__content-item--colour {
				background-color: $water-and-catchments;

				.featured-image__content-item--link {
	            	&:active,
					&:hover,
					&:focus {
						background-color: darken($water-and-catchments, 5%);
					}
	            }
			}
		}
	}
}
.forest-fire {
	.featured-image {
		&--small,
		&--large {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						border-top-color: $forest-fire;
					}
				}
				&--teal {
					background-color: $forest-fire;
					.featured-image__content-item--link {
		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($forest-fire, 5%);
						}
		            }
				}
			}
		}
		&--small {
			.featured-image__content-item {
				&--purple-light {
					background-color: $teal;

					.featured-image__content-item--link {
		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($teal, 5%);
						}
		            }
				}
				&--colour {
					background-color: $forest-fire;

					.featured-image__content-item--link {
		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($forest-fire, 5%);
						}
		            }
				}
			}
		}
	}
}
.climate-change {
	.featured-image {
		&--small,
		&--large {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						border-top-color: $climate-change;
					}
				}
				&--teal {
					background-color: $climate-change;
					.featured-image__content-item--link {
		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($climate-change, 5%);
						}
		            }
				}
			}
		}
		&--small {
			.featured-image__content-item--colour {
				background-color: $climate-change;

				.featured-image__content-item--link {
	            	&:active,
					&:hover,
					&:focus {
						background-color: darken($climate-change, 5%);
					}
	            }
			}
		}
	}
}
.energy,
.local-infrastructure {
	.featured-image {
		&--small,
		&--large {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						border-top-color: $energy;
						&:active,
						&:hover,
						&:focus {
							background-color: transparent;
						}
						&::after {
							background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
						}
						.featured-image__content-item{
							&--label,
							&--title {
								color: $white;
							}
						}
					}
				}
				&--teal {
					background-color: $energy;
					.featured-image__content-item {
						&--link {
			            	&:active,
							&:hover,
							&:focus {
								background-color: darken($energy, 5%);
							}
							&::after {
								background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
							}
			            }
						&--label,
						&--title {
							color: $white;
						}
					}
				}
			}
		}
		&--small {
			.featured-image__content-item--colour {
				background-color: $energy;

				.featured-image__content-item {
					&--link {
		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($energy, 5%);
						}
						&::after {
							background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
						}
		            }
					&--label,
					&--title {
						color: $white;
					}
				}
			}
		}
	}
}
.local-infrastructure {
	.featured-image {
		&--small,
		&--large {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						border-top-color: $local-infrastructure;
					}
				}
				&--teal {
					background-color: $local-infrastructure;
					.featured-image__content-item--link {
		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($local-infrastructure, 5%);
						}
		            }
				}
			}
		}
		&--small {
			.featured-image__content-item {
				&--purple-light {
					background-color: $teal;

					.featured-image__content-item--link {
		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($teal, 5%);
						}
		            }
				}
				&--colour {
					background-color: $local-infrastructure;

					.featured-image__content-item--link {
		            	&:active,
						&:hover,
						&:focus {
							background-color: darken($local-infrastructure, 5%);
						}
		            }
				}
			}
		}
	}
}

// Theme: Fishermans Bend
.fishermans-bend {
	.featured-image {
		&--large {
			.featured-image__content-item--large.featured-image__content-item--teal {
				.featured-image__content-item--link {
					border-top-color: $fishermans-bend;

					&::after {
						background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
					}
				}

				.featured-image__content-item--label,
				.featured-image__content-item--title {
					color: $corporate-services;
				}
			}

			.featured-image__content-item {
				&--purple-light {
				    background-color: $fishermans-bend-dark;
					
					.featured-image__content-item--link::after {
						background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
					}
				    .featured-image__content-item--link:hover,
				    .featured-image__content-item--link:focus {
						background-color:  darken($fishermans-bend-dark, 5%);
				    }

				    .featured-image__content-item--label,
					.featured-image__content-item--title {
						color: $white;
					}
				}

				&--teal {
				
					.featured-image__content-item--link {
						border-top-color: $fishermans-bend;
					}
					

					.featured-image__content-item--label,
					.featured-image__content-item--title {
						color: $corporate-services;
					}
				}
			}
		}
		&--small {
			.featured-image__content-item--large.featured-image__content-item--teal {
				.featured-image__content-item--link {
						border-top-color: $fishermans-bend;

						&::after {
							background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
						}
					}

				.featured-image__content-item--title,
				.featured-image__content-item--label {
					color: $corporate-services;
				}
			}

			.featured-image__content-item {
				&--corporate-services {
				    background-color: $fishermans-bend-dark;

				    .featured-image__content-item--link:hover,
				    .featured-image__content-item--link:focus {
						background-color:  darken($fishermans-bend-dark, 5%);
				    }
				}

				&--purple-light {
					background-color: $fishermans-bend;

					.featured-image__content-item--link:hover,
				    .featured-image__content-item--link:focus {
						background-color: darken($fishermans-bend, 5%);
				    }
				}

				&--purple-dark {
					background-color: $fishermans-bend;

					.featured-image__content-item--label,
					.featured-image__content-item--title {
						color: $corporate-services;
					}
					
					.featured-image__content-item--link::after {
						background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
					}

					.featured-image__content-item--link:hover,
				    .featured-image__content-item--link:focus {
						background-color: darken($fishermans-bend, 5%);
				    }
				}

				&--colour {
					background-color: $fishermans-bend-dark;

					.featured-image__content-item--link:hover,
				    .featured-image__content-item--link:focus {
						background-color: darken($fishermans-bend-dark, 5%);
				    }
				}
			}
		}
	}
}