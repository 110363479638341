/* Module: S */
.tabs.browse-tabs {
    background: $wild-sand;
    padding: 0 pxToRem(10);
    .lga-selector .right-box__search-wrapper {
        margin-top: pxToRem(30);
    }
    .lga-selector__content-wrapper {
        padding: pxToRem(20) 0;
    }
    .tabs__tab-list-wrapper {
        max-width: 77rem;
        margin: 0 auto;
    }
    .tabs__target {
        padding-top: 0;
    }
    .tabs__item:first-child .tabs__link {
        margin-right: pxToRem(20);
    }
    .tabs__link {
        opacity: 0.7;
        font-size: pxToRem(18);
        font-weight: 600;
        margin-right: 0;
        &.uber-accordion__button-active {
            opacity: 1;
        }
    }

    .tabs__tab-list {
        display: flex;
        align-items: flex-end;
    }
}