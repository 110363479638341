/* Module: S */
.carousel {
    position: relative;

    h1 {
    	font-weight: bold;
    }

    h1, h2, h3, p {
    	color: $white;
    }

    h1 {
    	font-size: 		pxToRem(24);
    	margin-bottom: 	pxToRem(40);
    }
}

.carousel__content {
    position: absolute;
	margin: 	0 auto;
	max-width: 	77em;
	padding: 	0 pxToRem(24);
	top: 108px;
    left: 50%;
	transform: translateX(-50%);
	width: 100%;
}

a.carousel__link {
	@include sq-transition(background-color 0.6s linear);
	color: 			 white;
	background: 	 $planning;
	padding: 		 pxToRem(12) pxToRem(64);
	text-decoration: none;
	display: inline-block;
	&:hover, &:focus {
		@include sq-transition(background-color 0.6s linear);
		background: $corporate-services;
	}
}

.carousel__item {
	height: 				pxToRem(420);
	background-repeat:  	no-repeat;
	background-size: 		cover;
	text-align: 			center;
	position: 				relative;
	background-position: 	center;
}

.carousel__overlay {
	position: 			absolute;
	height: 			100%;
	width: 				100%;
	background-color: 	#3c2b76;
	opacity: 			0.2;
	// background-image: linear-gradient(to bottom right,#002f4b,#dc4225);
	// opacity: 0.2;
}

.carousel__hidden-content {
	display: none;
}

.carousel__panels {
	position: 	 absolute;
	bottom: 	 0;
	width: 		 100%;	
	padding-top: pxToRem(40);
	background:  linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(77,30,80,0.8) 100%);

}
ul.carousel-nav {
	margin: 	0 auto;
	padding: 	0;
	max-width: 	pxToRem(1310);
	list-style: none;
	text-align: center;

	li {
		display: inline-block;

		button {
			border: 		none;
			font-size: 		0;
			height: 		pxToRem(13);
			width: 			pxToRem(13);
			padding: 		0;
			margin: 		0 pxToRem(4) pxToRem(33) pxToRem(4);
			border-radius: 	pxToRem(25);
			border: 		1px solid white;
			background: 	initial;
		}

		&.slick-active button {
			background: white;	
		}

		a {
			outline: 0;
		}
	}
}

