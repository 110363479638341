/* Module: S */
.lodge-ammendments-btn {
    float: right;
    margin-top: pxToRem(-40);
}
// PLANNING SCHEME PDF
.planning-scheme.full-width {
    .side-menu {
        .accordion__l2-content .accordion__l2-link {
            margin-left: pxToRem(50);
        }
        .accordion__l2-content .accordion__l2-link-counter {
            min-width: pxToRem(140);
            display: inline-block;
        }
    }
}

// PLANNING SCHEME MAP
.planning-scheme.full-width.planning-scheme-map {
    .planning-scheme__content-map {
        display: flex;
    }
    .side-menu {
        .accordion__l2-link-counter {
            width: 80%;
        }
    }
}
.planning-scheme.full-width.planning-scheme-map {

    .planning-scheme__vicplan {
        border-bottom: 1px solid $corporate-services;
        padding-bottom: pxToRem(55);

        &--wrapper {
            display: flex; 
            justify-content: space-between;

            &--intro {
                width: pxToRem(660);
            }

            &--button {
                padding-top: pxToRem(110);
                padding-left: pxToRem(15);

                .btn {
                    width: pxToRem(285);
                }
            }

        }
    }
}
// PLANNING SCHEME HISTORY
.planning-scheme__history {
    .link-boxes__item:not(:nth-child(3n)) {
        margin-right: pxToRem(15);
    } 
    .link-boxes__item:nth-child(2) {
        margin-right: 0;
    }
    .link-boxes__item {
        width: calc(50% - 15px);
    }
    
}