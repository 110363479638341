body {

    &::after {
        content: 'medium';
    }
}

.main-content-wrapper {

    p,
    ul,
    ol {
        font-size: pxToRem(16);
    }
}

/*
--------------------
Modules
--------------------
*/