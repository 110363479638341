/* Module: S */
.block-tile {
    padding-bottom: pxToRem(50);

    &.padding_bottom--half {
        padding-bottom: pxToRem(25);
    }
    &--header {
        text-align: center;
    }

    &__item {
        display:         flex;
        flex-direction:  column;
        justify-content: space-between;

        &--wrap {
            display:        flex;
            padding-bottom: pxToRem(8);
            padding-top:    pxToRem(8);
        }

        &-content {
            display:         flex;
            flex-direction:  column;
            flex-grow:       1;
            flex-shrink:     0;
            flex-wrap:       nowrap;
            justify-content: space-between;

            &-wrapper {
                flex-grow:   1;
                flex-shrink: 0;
            }
        }
    }
}

.search__result--tiles {

    .block-tile {

        &__item {
            max-width: 100%; // Prevent tiles from hijacking width of both tile and page
        }

    }

}
.block-tile--small {
    padding-bottom: 0.625rem;
}
// IE11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .block-tile__item--wrap {
        display: block;
    }

    .block-tile__item {
        height: 100%;
    }
}

//small news articles tile
.block-tile--news_articles_small {
    padding:       pxToRem(40) pxToRem(20) 0 pxToRem(20);
    border-bottom: 1px solid #00b7bd;

    .container-fluid {
        max-width: pxToRem(1024);
    }

    .block-tile--section_title-wrapper {
        padding:       0;
        margin-bottom: pxToRem(53);
        .block-tile--news_articles_small-section_title {
            font-size:      pxToRem(26);
            line-height:    pxToRem(35);
            text-align:     center;
            letter-spacing: normal;
        }
    }

    .block-tile__item-small--wrap {

        .block-tile__item-small {
            border:        none;
            padding:       0 pxToRem(78) 0 pxToRem(24);
            margin-bottom: pxToRem(50);

    		&--img {
                display:      block;
                margin-right: pxToRem(70);
                width:        26%;

    			img {
                    width:      100%;
                    height:     auto;
    			    margin:     0 auto;
    			    object-fit: cover;
    			}
    		}
    		&-content {
                display:        block;
                width:          63%;

                &--header {
                    margin:          0 0 pxToRem(20) 0;
                    padding-right:   pxToRem(135);
                    h3 {
                        font-size:      pxToRem(20);
                        line-height:    pxToRem(27);
                        font-style:     normal;
                        font-stretch:   normal;
                        letter-spacing: normal;
                    }
                }

                &--description {
                    p {
                        letter-spacing: 0;
                    }
                }
    		}
    	}

        &:last-of-type {
            .block-tile__item-small {
                margin-bottom: pxToRem(43);
            }
        }
    }
}
