/* Module: S */
.lga-selector,
.search__input {
    background: $wild-sand;
    padding-bottom: pxToRem(17);

    &__submit {
        position: absolute;
        top: 22px;
        left: 27px;
        z-index: 1;
        height: 25px;
        width: 25px;
        cursor: pointer;

        svg {
            stroke: #855487;
            width: 25px;
            height: 25px;
        }

        &--focus {
            svg {
                stroke: #642667;
            }
        }
    }

    &__close {
        display: none;
        position: absolute;
        top: 28px;
        right: 19px;
        background: transparent;
        border: none;
        width: 15px;
        height: 15px;
        margin: 0;
        padding: 0;

        svg {
            width: 15px;
            height: 15px;
            fill: #C8C8C8;
        }

        &--focus {
            display: block;
            svg {
                fill: #6F6F6F;
            }
        }
    }

    //autocomplete css
    span.twitter-typeahead {
        width: 100%;
        height: pxToRem(70);
    }

    &__content-wrapper {
        padding: 20px;
    }

    .text-box {
        &__title,
        &__description,
        &__link {
            margin: 10px 0;
        }
    }

    .right-box {
        &__search-wrapper {
            display: flex;
            margin-top: pxToRem(48);
            width: 100%;
            position: relative;
            float: right;

            .input-wapper {
                position: relative;
                width: 100%;
            }

            input[type="text"] {
                border: 1px solid $black;
                padding: 23px 35px 23px 60px;
                height: pxToRem(70);
                width: 100%;
                font-size: pxToRem(18);
                position: relative;
                background-color: $white;

                &:focus {
                    outline: none;
                    border: 1px solid $purple-dark;
                }
            }
            input[type='text']::-ms-clear {
                display: none;
                width: 0;
                height: 0;
            }
        }

        &__planning-scheme {
            float: right;
            position: relative;

            svg {
                position: absolute;
                left: -25px;
                top: 10px;
                fill: $planning;
            }
            
            &-link {
                margin-top: 12px;
                display: block;
            }
        }
    }
    a {
        color: $planning
    }

    // autosuggestion
    &--menu {
        box-shadow: 0 0 3px 0 rgba($corporate-services, 0.5);
        left: 0!important;
        margin-top: pxToRem(5);
        width: 100% !important;
    }
    &--dataset {
        max-height: 260px;
        overflow: hidden;
        overflow-y: inherit;
    }
    &--suggestion {
        padding: pxToRem(17) pxToRem(17) pxToRem(17) pxToRem(30);
        font-size: pxToRem(18);
        background: $white;
        color: $planning;
        font-family: 'VIC';

        span {
            display: inline-block;
            color: $grey;

            &:hover {
                color: $grey;
            }

            &:after {
                content: "|";
                padding-left: 5px;
            }
        }

        span.scheme {
            font-size: pxToRem(20);

            &:after {
                display: none;
            }   
        }

        &:hover {
            color: $corporate-services;
            background: $pink;
        }
    }
    &--empty {
        padding: pxToRem(30) pxToRem(111) pxToRem(34);
        background-color: $white;
        color: #855487;
        text-align: center;
    }
    &--selectable {
        cursor: pointer;
    }
    &--cursor {
        color: $corporate-services;
        background: $pink;

        &.search__input--view-all {
            background: $corporate-services;
            color: $white;
        }
    }
}

// close map button
.map-container {
    position: relative;
    display: none;
    &.active {
        display: block;
    }
}
.hide-map-button {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
    text-indent: -9999px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #f6f6f6;
    background-image: url(mysource_files/cancel-planning.svg);
    background-position: 50%;
    background-size: 12px;
    background-repeat: no-repeat;
}

// lga form popup
.lga-popup-container {
    background: #f6f6f6;
    box-shadow: 0 0 5px 0 #929292;
    display: none;
    padding: pxToRem(30);
    position: absolute;
    z-index: 2;
    width: calc(100% - 16px);
    margin-top: pxToRem(-57);

    &.active {
        display: block;
    }

    h2 {
        text-align: center;
        font-size: pxToRem(20);
        margin-top: 0;
    }

    &__close {
        right: pxToRem(15);
        top: pxToRem(15);
        position: absolute;
        background: transparent;
        border: none;
        width: 15px;
        height: 15px;
        margin: 0;
        padding: 0;
    }

    .lga-selector__content-wrapper {
        padding: 0;
    }
    .lga-selector .right-box__search-wrapper {
        margin-top: 0;
    }
    .lga-selector--menu {
        margin-top: 0;
        -moz-box-shadow: 0px 3px 6px rgb(100,100,100);
        -webkit-box-shadow: 0px 3px 6px rgb(100,100,100);
        box-shadow: 0px 3px 6px rgb(100,100,100);    
    }
    .lga-selector__submit {
        top: pxToRem(15);
    }
    .lga-selector .right-box__search-wrapper input[type="text"] {
        padding: 16px 35px 16px 60px;
    }
    .lga-selector__close {
        top: pxToRem(19);
    }
}