/* Module: S */
.search-results.resource-library-results {
    padding-right: 0;
    padding-left: 0;
    .container-fluid {
        padding: 0 pxToRem(10);
        max-width: pxToRem(953);
        margin: 0 auto;
    }
    .row {
        margin: 0;
    }
    .search-results__summary {
        margin-bottom: pxToRem(16);
    }
}

.resource-library__items {
    margin: pxToRem(15) 0 pxToRem(30);
}
.resource-library__item {
    border-top: 1px solid $silver1;
    padding: pxToRem(28) 0 pxToRem(45);
    &-wrapper-img img {
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        display: none;
    }
    &--status, &--info {
        color: $corporate-services;
        display: inline-block;
        font-size: pxToRem(12);
        font-weight: 600;
        padding: pxToRem(4) pxToRem(9);
        span {
            font-weight: normal;
        }
        &.resource-library__item--status--current {
            background: $teal;
        }
        &.resource-library__item--status--archived {
            background: $silver1;
        }
    }
    &--info {
        padding: 0;
    }
    &--date, &--doc-type, &--file-type {
        display: inline-block;
    }
    &--date, &--doc-type {
        display: inline-block;
        &:after {
            content:"/";
            display: inline;
            font-weight: normal;   
            padding-left: pxToRem(5);
        }
        &:last-child {
            &:after {
                content:none;
            }
        }
    }
    &--doc-type, &--file-type {
        padding-left: pxToRem(5);
        &:first-child {
            padding-left: 0;
        }
    }
    &--heading {
        font-size: pxToRem(20);
        font-weight: 600;
        margin: pxToRem(8) 0 pxToRem(6);
    }
    &--desc {
        margin: pxToRem(15) 0;
        color: $grey;
    }
    &--files {
        a {
            color: $planning;
            display: block;
            font-weight: 600;
            text-decoration: none;
            span {
                font-weight: normal;
            }
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
        .pdf_file, .word_doc, .excel_doc, .powerpoint_doc, .image, .link {
            margin: pxToRem(5) 0 pxToRem(5) 1.5rem;
            position: relative;
            &::before {
                content: "";
                background: url(mysource_files/file-pdf.svg) no-repeat center center transparent;
                width: pxToRem(19);
                height: pxToRem(22);
                display: block;
                position: absolute;
                background-size: contain;
                left: -1.5rem;
            }
        }
        .word_doc {
            &:before {
                content: "";
                background: url(mysource_files/word-icon.svg) no-repeat center center transparent;
                width: pxToRem(17);
                height: pxToRem(20);
            }
        }
        .excel_doc{
            &:before {
                content: "";
                background: url(mysource_files/excel-icon.svg) no-repeat center center transparent;
            }
        }
        .powerpoint_doc {
            &:before {
                content: "";
                background: url(mysource_files/powerpoint-icon.svg) no-repeat center center transparent;
            }
        }
        .image {
            margin-left: pxToRem(30);
            &:before {
                content: "";
                background: url(mysource_files/image-icon.svg) no-repeat center center transparent;
                width: pxToRem(25);
                left: pxToRem(-30);
            }
        }
        .link {
            &:before {
                content: "";
                background: url(mysource_files/external-link.svg) no-repeat center center transparent;
            }
        }
    }
    &--archived-vers {
        color: $corporate-services;
        font-size: pxToRem(12);
        font-weight: 600;
        padding: pxToRem(15) 0 pxToRem(25);
        span {
            color: $planning;
        }
    }
    &--related--btn.btn {
        background: $planning;
        font-size: pxToRem(14);
        margin: 0;
    }
}
.resource-library-content-drawer__content {
    h2 {
        font-size: pxToRem(20);
        border-bottom: 1px solid $planning;
        padding-bottom: pxToRem(10);
        margin-bottom: pxToRem(30);
    }
}