/* Module: S */
//Breadcrumbs
.amendments-header .breadcrumb {
    line-height: pxToRem(19);
    margin: 0;
    padding: pxToRem(27) 0 0 0;
}

.amendments-header .breadcrumb__item::after {
    font-size: pxToRem(14);
    letter-spacing: normal;
}

.amendments-header .breadcrumb a {
    font-size: pxToRem(14);
    letter-spacing: normal;
}

.amendments-header .search-header__title {
    margin: pxToRem(16) 0 pxToRem(16) 0;
}

.amendments-header .lga-selector__content-wrapper {
    padding: 0 pxToRem(16) pxToRem(100) pxToRem(16);
}