/* Module: S */
// 1. QUICKLINKS
.block-links {
    font-size: pxToRem(20);
    padding:   pxToRem(68) pxToRem(120) pxToRem(46) pxToRem(120);

    .container-fluid {
        max-width: pxToRem(1440);
    }

    .link-block {
        display: flex;
        &:nth-of-type(odd) {
            .block-links__link {
                margin-right: pxToRem(15);
            }
        }

        &:nth-of-type(even) {
            .block-links__link {
                margin-left: pxToRem(15);
            }
        }
    }

    .block-links__link {
        margin-bottom:     pxToRem(21);
        padding: pxToRem(30) pxToRem(84) pxToRem(30) pxToRem(29);

        &::after {
            right:  pxToRem(25);
        }

        .block-link__content {
            display:         flex;
            flex-direction:  column;
            flex-grow:       1;
            flex-shrink:     0;
            flex-wrap:       nowrap;
            justify-content: space-between;

            .block-link__content-inner {
                flex-grow:   1;
                flex-shrink: 0;

                .block-link__title {
                    margin-bottom: pxToRem(6);
                }

                .block-link__desp {
                    font-size:  pxToRem(15);
                }
            }
        }
    }
}
