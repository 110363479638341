body {

    &::after {
        content: 'wide';
    }
}

// DLP-595 Fix (RHS triangles overlaying content)
.main .container-fluid {
    position: relative;
    z-index:  10;
}

/*
--------------------
Modules
--------------------
*/