/* Module: S */
// 1. HEADER BANNER
.header-banner {
    max-height: pxToRem(340);

    &::before {
        display: block;
    }

    .col-md-6 {
        
        &:first-of-type {
            flex-basis: 48%;
            max-width: 48%;
        }

        &:last-of-type {
            flex-basis: 52%;
            max-width: 52%;
        }
    }

    .col-xs-12 {

        &:first-of-type {
            order: 1;
        }

        &:last-of-type {
            order: 2;
        }
    }

    .banner__text-wrapper {
        padding: 0 0 0 pxToRem(32);
    }

    .banner__image-wrapper {

        &::after {
            border-width: 0 pxToRem(54) pxToRem(114);
            left:         pxToRem(54);
        }

        &::before {
            right: pxToRem(-699);
        }
    }

    .banner__image-triangle {
        left: pxToRem(-768);
    }

    .banner__image {
        height: pxToRem(340);
    }
}
