/* Module: S */
.lga-selector {
    padding-bottom: pxToRem(34);

    &__close {
        // display: block;
    }

    .right-box {
        width: 60%;

        &__search-wrapper {
            width: 500px;
            float: left;
        }
        
        &__planning-scheme {
            float: left;
            margin-left: pxToRem(24);
        }
    }
}

// lga form popup
.lga-popup-container {
    .lga-selector .right-box__search-wrapper {
        width: 100%;
    }
    &__close {
        top: pxToRem(15);
    }
}
