/* Module: S */
.inside {

    .main  {

        &::before {
            display: block;
        }

        &::after {
            display: block;
        }

        > .container-fluid {
            padding-bottom: pxToRem(85);
            padding-top:    pxToRem(75);
        }

        .content-wrapper--one-col {

            p,
            ol,
            ul {
                width: 80%;
            }

        }

        &.col-2 {

            .main-content-wrapper {
                -ms-flex-preferred-size: 66.66666667%;
                flex-basis:              66.66666667%;
                max-width:               66.66666667%;
            }

            .lhs-nav-wrapper {
                display: block;
            }

            .content-wrapper,
            .block-quote,
            .accordion,
            .table-responsive,
            .tabs,
            .faq {
                -ms-flex-preferred-size: 100%;
                flex-basis:              100%;
                max-width:               100%;
            }
        }
    }
}

// Detailed Project Page
.project {
    .main {
        &>.container-fluid {
            padding-top: 0;
        }
    }
}

// Detailed Project Page
.project {
    .main {
        margin-top: pxToRem(-50);
    }
}

.project-section__sort {
    border: none;
    height: auto;
    position: relative;

    &::before {
        background: $wild-sand;
        border-bottom: solid 1px $grey-c;
        content: '';
        height: 100%;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 100vw;
    }

    .sort-project-section--selected {
        display: none;
    }

    &.expanded .sort-project-section__list, .sort-project-section__list {
        background: $wild-sand;
        border-bottom:   solid 1px $grey-c;
        display:         block;
        list-style-type: none;
        margin:          0;
        padding:         0;
        position: relative;
        top:             0;

        li {
            display:   inline-block;
        }
        .project-section__link {
            color:           $corporate-services;
            display:         inline-block;
            font-size:       pxToRem(20);
            padding:         pxToRem(10) pxToRem(20);
            text-decoration: none;
            cursor: pointer;
            &:hover,
            &:active {
                cursor:      pointer;
                color:       $corporate-services;
                font-weight: 500;
            }
        }
        .project-section--selected {
            border:        solid 1px $grey-c;
            border-bottom: none;
            background:    $white;
            font-weight:   bold;
        }
    }
    &.expanded {
        .sort-project-section__list {
            box-shadow: none;
            &::after {
                content: none;
            }
        }
    }
    //Todd added to fix selected tab border bottom
    .sort-project-section__list {
        .project-section__link {
            &.project-section--selected.active {
                position: relative;
                &:after {
                    content:          "";
                    position:         absolute;
                    bottom:           pxToRem(-1);
                    left:             0;
                    width:            100%;
                    height:           pxToRem(1);
                    background-color: $white;
                }
            }
        }
    }
}


