/* Module: S */
.planning-scheme-opened #main {
    z-index: 20;
}

body.planning-scheme-opened {
    overflow: hidden;
}
.scroll-y-content--mobile {
    height: calc(100% - 50px);
    overflow-y: scroll;
}
.relative {
    position: relative;
}

.search-header-right-buttons {
    display: none;
}
.lodge-ammendments-btn {
    background: $planning;
    padding: pxToRem(15) pxToRem(55) pxToRem(15) pxToRem(30);
    font-size: pxToRem(13);
    color: $white;
    text-decoration: none;
    position: relative;
    display: block;
    margin-left: pxToRem(-8);
    margin-right: pxToRem(-8);
    width: auto;
    &:after {
        content: "";
        background: url(mysource_files/lodge-icon.svg) no-repeat center center transparent;
        width: pxToRem(20);
        height: pxToRem(20);
        display: block;
        position: absolute;
        background-size: contain;
        right: pxToRem(25);
        top: 30%;
    }
    &:hover, &:focus {
        background: rgba($planning,0.90);
    }
}
// .planning-scheme {
//     .container-fluid {
//         padding: 0;
//     }
//     .back-btn {
//         display: block;
//         padding: pxToRem(20) pxToRem(20) pxToRem(20) pxToRem(40);
//         margin-top: pxToRem(20);
//         width: pxToRem(80);
//         color: $planning;
//         position: relative;
//         &:hover {
//             text-decoration: none;
//         }       
//         &:after {
//             content: "";
//             bottom: pxToRem(15);
//             left: 0;
//             width: 30px;
//             height: 30px;
//             display: block;
//             background: url(mysource_files/arrow--right.svg) no-repeat center center transparent;
//             text-indent: -999999px;
//             position: absolute;
//             transform: rotate(180deg);
//         } 
//     }
//     //RESTYLE SEARCH
//     &__search-wrapper {
//         background-color: #f6f6f6;
//         &>form {
//             padding: 10px;

//             .search__group {
//                 display: inline-block;
//                 position: relative;
//                 width: 100%;
//                 height: 100%;

//                 .search__input--menu.search__input--open {
//                     width: 100%;
//                 }
                
//                 .search__input {
//                    width: inherit;
//                    padding: 5px 20px 5px 0px;
//                    height: inherit;
//                    background-color: #f6f6f6;
//                    font-size: 1.125rem;
//                    font-weight: normal;
//                    color: #201547;
//                 }
//                 .search__submit.search__submit--icon {
//                     display: block;
//                     background: url(mysource_files/search-icon.svg) no-repeat top left transparent;
//                     background-size: contain;
//                     width: 20px;
//                     position: absolute;
//                     top: calc(50% - 10px);
//                     right: 0;
//                 }
               
//                 .search__submit {
//                     position: absolute;
//                     top: 0rem;
//                     right: -0.0625rem;
//                     width: 1.4375rem;
//                     height: 1.4375rem;
//                 }
             
//                 .search__submit.search__submit--cancel {
//                     display: none;
//                     width: 1.0625rem;
//                     height: 1.0625rem;
//                     background: url(mysource_files/cancel.svg) no-repeat top left transparent;
//                     background-size: contain;
//                     top: 0.3125rem;
//                 }
//                 //sugestions
//                 .search__input--menu {
//                     box-shadow: 0 0 3px 0 rgba(32, 21, 71, 0.5);
//                     margin-top: 0.3125rem;
//                 }

//                 .search__input--suggestion {
//                     padding: 1.1875rem 1.125rem;
//                     font-size: 1.125rem;
//                     background: #fff;
//                     color: $planning;
//                     font-family: 'VIC';
//                 }

//                 .search__input--view-all {
//                     background: $planning;
//                     color: #fff;
//                     font-size: 1rem;
//                     text-align: center;
//                 }

//                 .search__input--selectable {
//                     cursor: pointer;
//                 }
//             }
//         }
//     }
//     &__content-box {
//         background-color: #fff;
//         height: 100%;
//         position: fixed;
//         right: -64rem;
//         top: 0;
//         width: 100%;
//         z-index: 100;
//         .top-bar {
//             &__breadcrumbs {
//                 display: none;
//             }
//             &__buttons {
//                 margin: 0 10px;
//                 display: flex;
//                 justify-content: flex-end;
//                 a {
//                     position: relative;
//                     font-size: 13px;
//                     font-weight: 600;
//                     color: $valhalla;
//                     text-decoration: none;
//                     padding: 20px 10px 20px 30px;
//                     margin-left: 10px;
//                     &:hover {
//                         background-color: $planning;
//                         color: white;
//                     }
                    
//                 }
//                 &--print-button:after {
//                     content: "";
//                     background: url(mysource_files/print--dark-blue.svg) no-repeat center center transparent;
//                     width: 20px;
//                     height: 20px;
//                     display: block;
//                     position: absolute;
//                     background-size: contain;
//                     left: 5px;
//                     top: calc(50% - 12px);
//                 }
//                 &--print-button:hover:after {
//                     background: url(mysource_files/print--white.svg) no-repeat center center transparent;
//                 }
//                 &--view-map-button:after{
//                     content: "";
//                     background: url(mysource_files/pin--dark-blue.svg) no-repeat center center transparent;
//                     width: 20px;
//                     height: 28px;
//                     display: block;
//                     position: absolute;
//                     background-size: contain;
//                     left: 5px;
//                     top: calc(50% - 16px);
//                 }
//                 &--view-map-button:hover:after {
//                     background: url(mysource_files/pin--white.svg) no-repeat center center transparent;
//                 }
//             }
//         }
//         .content-box {
//             border-top: 1px solid $silver1;
//             margin: 0 10px;
//             padding: 20px 0;
//             &__numbers-wrapper{
//                 display: flex;
//                 flex-wrap: nowrap;
//                 margin: 5px 0;
//                 &--date,
//                 &--symbol,
//                 &--counter {
//                     font-size: 12px;
//                     font-weight: 500;
//                     margin-right: 8px;
//                 }
//                 &--counter {
//                     font-size: 25px;
//                     font-weight: bold;
//                     color: $planning;
//                     margin-top: 0;
//                     margin-bottom: 0;
//                 }
//             }
//             &__content-wrapper {
//                 font-size: 16px;
//                 font-weight: normal;
//                 line-height: 1.5;
//                 color: #6f6f6f;
//                 &-heading {
//                     &--purple {
//                         font-size: 16px;
//                         font-weight: bold;
//                         margin: 5px 0;
//                         color: $planning;
//                     }
//                     &--dark-blue {
//                         font-size: 14px;
//                         font-weight: bold;
//                         color: #211547;
//                     }
//                 }
//                 h1 {
//                     font-size: 24px;
//                     font-weight: bold;
//                 }
//                 h2 {
//                     font-size: 20px;
//                     font-weight: bold;
//                     margin-top: 5px;
//                 }
//             }
//         }
//     }
    
// }
// PLANNING SCHEME MAP
.planning-scheme.full-width.planning-scheme-map {

    .planning-scheme__vicplan {
        background: $wild-sand;
        margin-bottom: pxToRem(5);
        padding-top: pxToRem(10);

        & > .container-fluid {
            max-width: 77rem;
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }

    .planning-scheme__content-map {
        display: none;
        justify-content: space-between;
        padding: pxToRem(70) pxToRem(10);
        &-enlagrement {
            font-size: pxToRem(17);
            list-style-type: none;
            border: 1px solid $planning;
            // height: pxToRem(205);
            width: pxToRem(300);
            padding: 0;
            margin-right: pxToRem(20);
            display: inline-table;
            li {
                border-bottom: 1px solid $planning;
                margin-left: pxToRem(10);
                &:last-child {
                    border-bottom: none;
                } 
            }
            a, span {
                text-decoration: none;
                display: block;
                color: $corporate-services;
                padding: pxToRem(22) pxToRem(10) pxToRem(22) pxToRem(30);
                margin-left: pxToRem(-10);
            }
            span.enlargement-link-active {
                background: $wild-sand;
            }
            a {
                &:hover, &:focus {
                    text-decoration: none;
                    background: $wild-sand;
                }
            }
        }
    }
 
    .side-menu {
        .accordion__l1-button {
            font-size: pxToRem(16);
            font-weight: 600;
        }
        .accordion__l1.open .accordion__l1-content {
            padding-right: 0;
        }
        .accordion__l2-link, .accordion__l2-link-counter {
            font-size: pxToRem(16);
            font-weight: normal;
        }
        .accordion__l2-link {
            margin-left: pxToRem(10);
            color: $grey;
            padding: pxToRem(10) pxToRem(50) pxToRem(10) 0;
            position: relative;
            &:nth-child(2) {
                padding-top: pxToRem(20);
            }
            &:last-child {
                padding-bottom: pxToRem(20);
            }
            &:hover {
                .accordion__l2-link-counter {
                    text-decoration: underline;
                }
            }
        }
        .accordion__l2-date {
            right: pxToRem(20);
            position: absolute;
        }
        .accordion__l2-link-counter {
            width: 60%;
            display: inline-block;
        }
        .accordion__l2-link.accordion__l2-heading {
            font-size: pxToRem(11);
            font-weight: 600;
            color: $corporate-services;
            border-bottom: 1px solid $corporate-services;
            text-transform: uppercase;
            padding-bottom: pxToRem(5);
            padding-top: pxToRem(20);
            .accordion__l2-link-counter {
                font-size: pxToRem(11);
                font-weight: 600;
                color: $corporate-services;
            }
            &:hover {
                .accordion__l2-link-counter {
                    text-decoration: none;
                }
            }
        }

    }
    
}
// PLANNING SCHEME HISTORY
.link-boxes {
    display: flex;
    flex-wrap: wrap;
    &__item {
        width: 30%;
        position: relative;
        padding: 20px 20px 40px 20px;
        border: solid 1px #c7c7c7;
        color: $valhalla;
        margin-bottom: 15px;
        text-decoration: none;
        &:not(:nth-child(3n)) {
            margin-right: 2%;
        }
        &:hover {
            background-color: $valhalla;
            color: white;
            &:after {
                background: url(mysource_files/arrow--right--white.svg) no-repeat center center transparent;
            }
        }
        &:after {
            content: "";
            bottom: 10px;
            right: 20px;
            width: 30px;
            height: 30px;
            display: block;
            background: url(mysource_files/arrow--right.svg) no-repeat center center transparent;
            text-indent: -999999px;
            position: absolute;
        }
    }
    &__title {
        font-size: 18px;
        font-weight: bold;
        margin: 5px;
    }
    &__description {
        font-size: 15px;
        font-weight: 500;
    }
}

.planning-scheme__history {
    padding: pxToRem(20) pxToRem(10);
    &-heading {
        font-size: pxToRem(35);
        color: $corporate-services;
        font-weight: 600;
        margin-bottom: pxToRem(20);
    }
    .link-boxes__item:not(:nth-child(3n)) {
        margin-right: 0;
    } 
    .link-boxes__item:nth-child(2) {
        margin-right: 0;
    }
    .link-boxes__item {
        width: 100%;
    }
    .link-boxes__title {
        font-size: pxToRem(15);
    }
    
}
//LEFT ACCORDION MENU
.side-menu {
    &-reset-button {
        font-size: 12px;
        text-decoration: none;
        color: $valhalla;
        padding: 10px;
        float: right;
    }
    .accordion {
        padding: 0;
        &__item {
            border-top: solid 1px $silver1;
            margin-bottom: 0;
            &-header {
                background-color: white;
            }
        }
        // &__link {
        //     font-size: 15px;
        //     font-weight: normal;
        //     padding: 17px 40px 17px 20px;
        //     &.uber-accordion__button-active {
        //         background-color: $planning;
        //         span {
        //             color: white;
        //         }
        //         &:after {
        //             background: url(mysource_files/minus.svg) no-repeat center center transparent;
        //         }
        //     }
        //     &:after {
        //         background: url(mysource_files/plus.svg) no-repeat center center transparent;
        //         -webkit-transition: none;
        //         -moz-transition: none;
        //         transition: none;
        //     }
        // }
        // &__counter {
        //     font-size: 20px;
        //     font-weight: bold;
        //     margin-right: 5px;
        //     color: $planning;
        // }

        // &__target.uber-accordion__target.uber-accordion__target-active {
        //     padding: 0 10px;
        // }

        //CUSTOM ACCORDION
        &__l1-button-counter {
            font-size: 20px;
            font-weight: bold;
            margin-right: 5px;
            color: #642667;
        }

        &__l2,
        &__l3,
        &__l4 {
            position: relative;
            &-header {
                position: relative;
            }
        }

        &__l2:after {
            content: "";
            position: absolute;
            // width: calc(100% - 70px);
            width: 100%;
            height: 1px;
            background-color: #c8c8c8;
            top: 0;
            // right: 10px;
        }
        &__l3:after {
            content: "";
            position: absolute;
            // width: calc(100% - 70px);
            width: 100%;
            height: 1px;
            background-color: #c8c8c8;
            top: 0;
            // right: 10px;
        }
        &__l4:after {
            content: "";
            position: absolute;
            // width: calc(100% - 70px);
            width: 100%;
            height: 1px;
            background-color: #c8c8c8;
            top: 0;
            // right: 10px;
        }

        &__l2-link,
        &__l3-link,
        &__l4-link {
            text-decoration: none;
            color: $valhalla;
            padding: 20px 0;
            margin-left: 50px; //
            display: block;
            &-counter {
                font-weight: bold;
                margin-right: 5px;
            }
            &.active {
                font-weight: 600;
                color: #642667;
            }
            &:hover {
                text-decoration: underline;
            }
        }
        &__l2-link,
        &__l3-link {
            font-size: 14px;
        }
        &__l4-link {
            font-size: 12px;
        }

        &__l1-button {
            font-size: 15px;
            font-weight: normal;
            padding: 17px 40px 17px 20px;
            text-decoration: none;
            display: block;
            color: #201547;
            position: relative;
            &:focus,
            &:hover {
                outline: none;
                background-color: rgba(92, 92, 92, 0.15);
            }
            &:after {
                content: '';
                width: 30px;
                height: 30px;
                position: absolute;
                right: 18px;
                top: calc(50% - 15px);
                background: url(mysource_files/plus.svg) no-repeat center center transparent;
            }
        }
        &__l2-button,
        &__l3-button {
            width: 30px;
            height: 30px;
            display: block;
            background: url(mysource_files/plus.svg) no-repeat center center #f4e1f4;
            text-indent: -999999px;
            position: absolute;
            top: calc(50% - 15px);
            left: 0px; 
            outline: none;
        }
        &__l1-content,
        &__l2-content,
        &__l3-content {
            margin-left: 50px;
            -webkit-transition: all 0.15s ease;
            -moz-transition: all 0.15s ease;
            transition: all 0.15s ease;
            max-height: 0;
            opacity: 0;
            visibility: hidden;
        }
    }
}

//accordion events
.accordion__l1.open  .accordion__l1-button {
    color: white;
    background-color: $planning;
    &-counter {
        color: white;
    }
    &:after {
        background: url(mysource_files/minus.svg) no-repeat center center $planning;
    }
    &:focus,
    &:hover {
        opacity: 0.8;
    }
}

.planning-scheme.full-width {
    .planning-scheme__content-desc {
        padding: pxToRem(20);
        font-size: pxToRem(16);
        color: $grey;
    }
    .planning-scheme__content-desc-pdf {
        background: $wild-sand;
    }
    .planning-scheme__content-desc-pdf-top {
        background: $cornflower-blue;
        padding: pxToRem(20);
        color: $corporate-services;
    }
    .planning-scheme__content-desc-pdf-top_heading {
        font-weight: 600;
        margin-top: 0;
        margin-bottom: pxToRem(5);
    }
    .planning-scheme__content-desc-text {
        div p:first-child {
            font-size: pxToRem(19);
            line-height: 27px;
            color: $corporate-services;
            a {
                color:$corporate-services;
            }
        }
    }
    .planning-scheme__content-desc-pdf-text {
        padding: pxToRem(10) pxToRem(20) pxToRem(20);
        font-size: pxToRem(14);
    }
    .planning-scheme__update-date {
        font-size: pxToRem(14);
        color:$corporate-services;
        margin: 0;
        span {
            font-weight: 600;
        }
    }
    .planning-scheme__download-btn {
        background: $planning;
        color: $white;
        text-decoration: none;
        padding: pxToRem(15) pxToRem(20) pxToRem(15) pxToRem(45);
        display: block;
        max-width: pxToRem(310);
        margin-bottom: pxToRem(14);
        margin-top: pxToRem(20);
        position: relative;
        &:before {
            content: "";
            background: url(mysource_files/file-pdf-white.svg) no-repeat center center transparent;
            width: 1rem;
            height: 1rem;
            display: block;
            position: absolute;
            background-size: contain;
            left: pxToRem(19);
            top: pxToRem(17);
        }
        &:hover, &:focus {
            background: rgba($planning, 0.90);
        }
    }
    .side-menu {
        .accordion__l1 {
            border-bottom: solid $white 3px;
            overflow: hidden;
        }
        .accordion__l1-button {
            background-color: $wild-sand;
            &:hover, &:focus {
                background-color: rgba($grey, 0.15);
            }
        }
        .accordion__l1.open .accordion__l1-button {
            background-color: $planning;
            color: $white;
        }
        .accordion__l1.open  .accordion__l1-button-counter  {
            color: $white;
        }
        .accordion__l2.indent__l2{
            padding-left: pxToRem(16);
            margin-left: pxToRem(32);
        }
        .accordion__l2:first-child {
            &:after {
                content: none;
            }
        }
        .accordion__l1-button-counter, .accordion__l2.open .accordion__l2-link, .accordion__l3.open .accordion__l3-link {
            color: $corporate-services;
        } 

        .accordion__l2-content .accordion__l2-link {
            margin-left: 0;
        }
        .accordion__l2-content .accordion__l2-link-counter {
            display: block;
        }
    }  
}

.accordion__l2.open  .accordion__l2-button,
.accordion__l3.open .accordion__l3-button {
    background: url(mysource_files/minus.svg) no-repeat center center $planning;
}

.accordion__l2.open .accordion__l2-link,
.accordion__l3.open .accordion__l3-link,
.accordion__l4.open .accordion__l4-link {
    color: $planning;
}

.accordion__l1.open .accordion__l1-content {
    max-height: inherit;
    opacity: 1;
    visibility: visible;
    padding: 0 10px;
    margin: 0;
}

.accordion__l2.open .accordion__l2-content,
.accordion__l3.open .accordion__l3-content,
.accordion__l4.open .accordion__l4-content {
    margin-left: 40px;
    max-height: inherit;
    opacity: 1;
    visibility: visible;
}

//BOX UNDER THE ACCORDION MENU
.pdf-link--box {
    border: 1px solid $valhalla;
    display: block;
    margin: 10px 10px 20px 10px;
    &__wrapper {
        border-bottom: 1px solid $fishermans-bend;
    }
    &__link {
        font-size: 12px;
        font-weight: 600;
        text-decoration: none;
        color: $valhalla;
        display: block;
        padding: 15px 20px;
        position: relative;
        margin-left: 20px;
        &:before {
            content: "";
            background: url(mysource_files/file-pdf--purple.svg) no-repeat center center transparent;
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            background-size: contain;
            left: -5px;
            top: calc(50% - 10px);
        }
    }
}

.mobile-close-bar {
    background-color: $wild-sand;
    border-bottom: 1px solid $silver1;
    &__button {
        position: relative;
        display: inline-block;
        padding: 10px 10px 10px 40px;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        &:after {
            content: "";
            background: url(mysource_files/drawer-close--planning.svg) no-repeat center center transparent;
            width: 25px;
            height: 25px;
            display: block;
            position: absolute;
            background-size: contain;
            left: 10px;
            top: calc(50% - 13px);
        }
    }
}

.social-media-inline {
    width: 30px;
    height: 30px;
    background-color: $grey;
    text-indent: -999999px;
    border-radius: 50%;
    margin-right: 5px;
    position: relative;
    &:hover {
        background-color: $valhalla;
    }
    &-wrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: 5px 0;
    }
    &-text {
        font-size: 12px;
        font-weight: 600;
        color: $grey; 
        margin-right: 5px;
    }
    &--facebook:after {
        content: "";
        background: url(mysource_files/facebook--white.svg) no-repeat center center transparent;
        width: 10px;
        height: 15px;
        display: block;
        position: absolute;
        background-size: contain;
        left: 10px;
        top: calc(50% - 8px);
    }
    &--twitter:after {
        content: "";
        background: url(mysource_files/twitter--white.svg) no-repeat center center transparent;
        width: 13px;
        height: 15px;
        display: block;
        position: absolute;
        background-size: contain;
        left: 9px;
        top: calc(50% - 7px);
    }
    &--linkedIn:after {
        content: "";
        background: url(mysource_files/linkedin--white.svg) no-repeat center center transparent;
        width: 12px;
        height: 15px;
        display: block;
        position: absolute;
        background-size: contain;
        left: 9px;
        top: calc(50% - 8px);
    }
    &--email:after {
        content: "";
        background: url(mysource_files/mail--white.svg) no-repeat center center transparent;
        width: 14px;
        height: 15px;
        display: block;
        position: absolute;
        background-size: contain;
        left: 8px;
        top: calc(50% - 8px);
    }
}
// loading
.load-container {
    width: 100%;
    background: $planning;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: none;
    p {
        font-size: pxToRem(24);
        font-weight: 600;
        color: $white;
    }
}
.loader {
    font-size: pxToRem(10);
    margin: pxToRem(50) auto;
    text-indent: -9999em;
    width: pxToRem(80);
    height: pxToRem(80);
    border-radius: 50%;
    background: $white;
    background: -moz-linear-gradient(left, $white 10%, rgba($white, 0) 42%);
    background: -webkit-linear-gradient(left, $white 10%, rgba($white, 0) 42%);
    background: -o-linear-gradient(left, $white 10%, rgba($white, 0) 42%);
    background: -ms-linear-gradient(left, $white 10%, rgba($white, 0) 42%);
    background: linear-gradient(to right, $white 10%, rgba($white, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.loader:before {
    width: 50%;
    height: 50%;
    background: $white;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.loader:after {
    background: $planning;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
    