/* Module: S */
// 1. FEATURE PANEL
.topic-tiles {
    padding: pxToRem(84) 0;

    .container-fluid {
        max-width: pxToRem(1440);

        .row {
            padding: 0 pxToRem(105);
        }
    }

    .topic-tiles__heading {
        font-size:   pxToRem(36);
        margin:      0 0 pxToRem(57) 0;
        line-height: pxToRem(49);
    }

    .topic-tile__block--wrap {
        margin:     0 pxToRem(14) pxToRem(28) pxToRem(14);
        max-width:  pxToRem(270);
    }

    .topic-tile__block {
        padding:     pxToRem(172) pxToRem(49) pxToRem(45) pxToRem(50);

        html.no-touch &:hover,
        html.no-touch &:focus {
            padding: pxToRem(36) pxToRem(40) pxToRem(60) pxToRem(23);

            .topic-tile__heading-wrap {
                .topic-tile__heading {
                    font-size:   pxToRem(18);
                }
            }

            .topic-tile__desp-wrap {
                height:         pxToRem(121);
                font-size:      pxToRem(15);
                line-height:    pxToRem(20);
                padding-top:    pxToRem(15);
                margin:         0;
            }
        }

        .topic-tile__image {
            width:     pxToRem(90);
            height:    50%;
            top:       35%;
            left:      50%;
            transform: translate(-50%, -35%);
        }

        .topic-tile__image--wide {
            width: pxToRem(120);
        }

        .topic-tile__heading-wrap {
            height: pxToRem(48);
            .topic-tile__heading {
                font-size:   pxToRem(18);
                line-height: pxToRem(24);
            }
        }
    }
}

.topic-tile--popup {
    .row {
        max-width: pxToRem(1440);
        margin:    0 auto;
    }

    .l1--wrap {
        padding: pxToRem(62) 0 pxToRem(107) 0;

        .popup--close-btn {
            &::before {
                position:        absolute;
                width:           pxToRem(73);
                height:          pxToRem(73);
                left:            pxToRem(120);
                background-size: auto;
            }
        }

        .l1--img-wrap {
            width:  pxToRem(114);
            margin: 0 auto pxToRem(43) auto;
        }

        .l1--heading-wrap {
            .l1--heading {
                font-size:      pxToRem(28);
                letter-spacing: -0.6px;
                margin:         0;
                line-height:    pxToRem(38);
            }
        }
    }

    .l2--tile-wrap,
    .l3--tile-wrap {
        max-width:    pxToRem(398);
        margin-right: pxToRem(3);

        &:last-of-type {
            margin-right: pxToRem(0);
        }
    }

    .l2--wrap {
        margin-top:    pxToRem(-43);
        margin-bottom: pxToRem(24);

        .l2--tile-wrap {
            padding:       pxToRem(30) pxToRem(14) pxToRem(33) pxToRem(83);
            position:      relative;
            border-bottom: none;

            .l2--tile {

                .l2--img-wrap,
                .l2-content {
                    float:none;
                }

                .l2--img-wrap {
                    position: absolute;
                    left:     pxToRem(25);
                    top:      pxToRem(33);
                }

                .l2-content {
                    width: 100%;
                    .l2-content--heading-wrap {
                        margin-bottom:   pxToRem(5);
                        .l2-content--heading {
                            font-size:   pxToRem(18);
                        }
                    }

                    .l2-content--desp-wrap {
                        .l2-content--desp {
                            font-size: pxToRem(16);
                        }
                    }
                }
            }
        }
    }

    .l3--wrap:not(.l3--wrap__heading-wrap) {
        padding-bottom: pxToRem(135);

        .l3--tile-wrap {
            .l3--heading-wrap {
                display: none;
            }
        }
    }

    .l3--wrap {
        &.l3--wrap__heading-wrap {
            display: flex;
        }
    }

    .l3--wrap__heading-wrap {
        .l3--tile-wrap {
            padding-top:    pxToRem(10);
            padding-bottom: 0;

            .l3--heading-wrap {
                margin-bottom: 0;

                .l3--heading {
                    margin-bottom: 0;
                }
            }
        }
    }

    .l3--tile-wrap {
        padding: pxToRem(20) pxToRem(0) pxToRem(20) pxToRem(30);

        .l3--heading-wrap {
            margin-bottom: pxToRem(28);
            .l3--heading {

            }
        }

        .l3--links-list {
            .l3--link-item {
                margin-bottom:    pxToRem(17);
            }
        }
    }
}
