/* Module: S */
.block-tile {
    padding-bottom: pxToRem(90);
    padding-top:    pxToRem(41);

    &.padding_top--half {
        padding-top: pxToRem(20);
    }

    &.padding_bottom--half {
        padding-bottom: pxToRem(45);
    }
    &--header {
        text-align: center;
        h2 {
            font-size: pxToRem(40);
            margin-bottom: pxToRem(27);
        }
    }

    &__item {
        margin-bottom: 0;

        &-content {

            &--header h3,
            &--header strong {
                font-size: pxToRem(24);
            }
        }
    }
}
.block-tile--small {
    padding-bottom: 0.625rem;
}


//small news articles tile
.block-tile--news_articles_small {
    padding: pxToRem(65) pxToRem(105) pxToRem(48) pxToRem(105);

    .container-fluid {
        max-width: pxToRem(1440);
        padding: 0;
    }

    .block-tile--section_title-wrapper {
        padding:       0;
        margin-bottom: pxToRem(67);
        .block-tile--news_articles_small-section_title {
            font-size: pxToRem(36);
        }
    }

    .block-tile_container {
        max-width: pxToRem(1230);
        margin:    0 auto;

        .block-tile__item-small--wrap {
            max-width: pxToRem(615);
            padding:   pxToRem(0);

            .block-tile__item-small {
                max-width:       pxToRem(615);
                flex-direction:  row;
                padding:         pxToRem(8) pxToRem(0) pxToRem(22) pxToRem(0);
                margin:          0 pxToRem(25) pxToRem(11) pxToRem(23);

        		&--img {
                    margin-right: pxToRem(38);
                    width:        45%;
        		}

        		&-content {
                    width:        55%;
                    margin-top:   pxToRem(-5);

                    &--header {
                        margin:          0 0 pxToRem(20) 0;
                        padding-right:   pxToRem(21);
                        h3 {
                            font-size:   pxToRem(20);
                            line-height: normal;
                        }
                    }

        			&--description {
                        p {
                            font-size:      pxToRem(16);
                            line-height:    normal;
                            letter-spacing: 0;
                        }
        			}
        		}
        	}
        }
    }
}
