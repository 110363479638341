/* Module: S */
// 1. HEADER BANNER HOME
.header-banner {
    background-position: -999px;
    background-repeat: no-repeat;
    color:      $corporate-services;
    position:   relative;

    &::before {
        border-color: transparent transparent $teal;
        border-style: solid;
        border-width: 0 pxToRem(33) pxToRem(71);
        bottom:       0;
        content:      '';
        display:      none;
        height:       0;
        left:         pxToRem(-33);
        position:     absolute;
        width:        0;
    }

    .row {
        margin: 0 pxToRem(-16);
    }

    .col-xs-12 {
        padding: 0;
        width:   100%;

        &:first-of-type {
            order: 2;
        }

        &:last-of-type {
            order: 1;
        }
    }

    .banner__text-wrapper {
        padding: pxToRem(30) pxToRem(16);
    }

    .banner__heading {
        font-size:   pxToRem(38);
        font-weight: 700;
        line-height: pxToRem(50);
        margin:      0;
    }

    .banner__subheading {
        font-size:   pxToRem(16);
        font-weight: 600;
        margin:      pxToRem(12) 0 0;
    }

    .banner__image-wrapper {
        height:   100%;
        overflow: hidden;
        position: relative;
        right:    0;
        top:      0;

        &::after,
        &::before {
            border-color: transparent transparent $navy;
            border-style: solid;
            bottom:       0;
            content:      '';
            display:      block;
            height:       0;
            opacity:      .5;
            position:     absolute;
            width:        0;
            z-index:      3;
        }

        &::after {
            border-width: 0 pxToRem(39) pxToRem(82);
            left:         pxToRem(36);
        }

        &::before {
            border-width: 0 pxToRem(465) pxToRem(983);
            right:        pxToRem(-746);
        }
        .corporate & {
            &::after,
            &::before {
                border-color: transparent transparent $climate-change;
            }

            &::after {
                opacity: 1;
            }
        }
        .planning & {

            &::after,
            &::before {
                border-color: transparent transparent $planning;
            }

            &::after {
                opacity: 1;
            }
        }

        .environment & {

            &::after,
            &::before {
                border-color: transparent transparent $environment;
            }

            &::after {
                opacity: 1;
            }
        }

        .climate-change & {

            &::after,
            &::before {
                border-color: transparent transparent $climate-change;
            }

            &::after {
                opacity: 1;
            }
        }

        .energy & {

            &::after,
            &::before {
                border-color: transparent transparent $energy;
            }

            &::after {
                opacity: 1;
            }
        }

        .water-and-catchments & {

            &::after,
            &::before {
                border-color: transparent transparent $water-and-catchments;
            }

            &::after {
                opacity: 1;
            }
        }

        .local-infrastructure & {

            &::after,
            &::before {
                border-color: transparent transparent $local-infrastructure;
            }

            &::after {
                opacity: 1;
            }
        }

        .forest-fire & {

            &::after,
            &::before {
                border-color: transparent transparent $forest-fire;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    .banner__image-triangle {
        border-color: transparent transparent $white;
        border-style: solid;
        border-width: 0 pxToRem(465) pxToRem(983);
        bottom:       0;
        left:         pxToRem(-816);
        position:     absolute;
    }

    .banner__image {
        background-color:    transparent;
        background-position: center center;
        background-repeat:   no-repeat;
        background-size:     cover;
        display:             block;
        height:              pxToRem(240);
    }
}
// Theme: Fishermans Bend
.fishermans-bend {

    .header-banner::before {
        border-color: transparent transparent $fishermans-bend;
    }

    .banner__image-wrapper {

        &::before {
            border-color: transparent transparent $fishermans-bend;
        }

        &::after {
            border-color: transparent transparent $fishermans-bend;
            opacity:      1;
        }
    }
}